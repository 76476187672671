import { useState } from 'react';
import { ToastFailed } from '../component/common/Toast';
import { apiCheckRemoveAllItemOfMasterData } from '../services/Site';

const useRemoveAllItemCheck = () => {
    const [loadingCheckListIds, setLoadingCheckListIds] = useState(false);

    const checkReturnListIdsExist = async ({ siteId, entityName, masterDataIds }) => {
        setLoadingCheckListIds(true);
        try {
            const response = await apiCheckRemoveAllItemOfMasterData({ siteId, entityName, masterDataIds });
            return response;
        } catch (e) {
            console.log(e);
            ToastFailed("Something went wrong, please try again.");
            return null;
        } finally {
            setLoadingCheckListIds(false);
        }
    };

    return { loadingCheckListIds, checkReturnListIdsExist };
};

export default useRemoveAllItemCheck;
