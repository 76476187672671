import React from "react";
import Logo from "../../../assets/images/timesheet_logo_2.png"
import "../style.scss"
export const HeaderLogin = () => {

    return (
        <header className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
                <img src={Logo} alt="logo" height={40} />
            </div>
            <div className="d-flex gap-3">
            </div>
        </header>
    );
};
