import React, { useState } from "react";
import Logo from "../../../assets/images/timesheet_logo_2.png"
import ImgProfile from "../../../assets/images/img_profile.png"
import { Dropdown, Popconfirm, Space } from 'antd';
import { KeyOutlined, LeftCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../../Routes";
import "../style.scss"
import { logoutSuccess } from "../../../store/actions/authAction";
import { useDispatch } from "react-redux";
import ModalChangePassword from "../../auth/ModalChangePassword";

export const Header = () => {
    const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false);

    const userData = JSON.parse(localStorage.getItem('timesheet_userData'));
    const navigate = useNavigate()
    const dispatch = useDispatch()

    function handleLogout() {
        localStorage.removeItem("timesheet_userData");
        localStorage.removeItem("timesheet_isLoggedIn");
        dispatch(logoutSuccess())
        navigate(Routes.Login.path)
    }
    const showModalChangePassword = () => {
        setIsOpenModalChangePassword(true);
    }

    const items = [
        {
            label:
                <Link to={Routes.Management.path}>
                    <div className="d-flex gap-3"><LeftCircleOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /> <span>Main menu</span></div>
                </Link>,
            key: '0',
        },
        {
            label: <div className="d-flex gap-3" onClick={showModalChangePassword}><KeyOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /><span>Change Password</span></div>,
            key: '1',
        },
        {
            label:
                <Popconfirm
                    title="Are you sure you want to log out?"
                    okText="OK"
                    cancelText="Cancel"
                    onConfirm={handleLogout}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="d-flex gap-3"><LogoutOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /> Logout</div>
                </Popconfirm>,
            key: '3',
        },
    ];

    return (
        <header className="d-flex justify-content-between align-items-center">
            <ModalChangePassword
                isModalOpen={isOpenModalChangePassword}
                setIsModalOpen={setIsOpenModalChangePassword} />
            <div className="d-flex justify-content-between align-items-center">
                <img src={Logo} alt="logo" height={40} />
            </div>
            <div className="d-flex gap-3">
                <Dropdown
                    menu={{
                        items,
                    }}
                    trigger={['click']}
                    className="cursor-pointer"
                >
                    <Space>
                        <div className="d-flex gap-2">
                            <img src={ImgProfile} width={24} height={24} alt="image" />
                            <div>{userData?.fullName}</div>
                        </div>
                    </Space>
                </Dropdown>
            </div>
        </header>
    );
};
