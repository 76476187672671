import { Modal } from "antd";
import React, { useState } from "react";
import Button from "../common/Button";
import { Status } from "../../utils/Status";
import { FormatTime } from "../../utils/FormatTime";

export default ({ isModalOpen, setIsModalOpen, dataDetail }) => {

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <Modal title={dataDetail?.siteName} open={isModalOpen} footer={null} className='modal-detail-site'>
            <div className="site-detail-data">
                <div className="d-flex gap-2">
                    <label>Site ID</label>
                    <div>{dataDetail?.siteId}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Remark</label>
                    <div>{dataDetail?.remark}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Status</label>
                    <div>{dataDetail?.status === Status.Enable ? "Enable" : "Disable"}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Created by</label>
                    <div>{dataDetail?.createdBy}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Created Date</label>
                    <div>{FormatTime(dataDetail?.createdAt)}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Update by</label>
                    <div>{dataDetail?.lastModifiedBy ? dataDetail?.lastModifiedBy : "--"}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>Update date</label>
                    <div>{FormatTime(dataDetail?.updatedAt)}</div>
                </div>
                <div className="d-flex gap-2">
                    <label>User</label>
                    <div>{dataDetail?.listUsers?.map(user => user.fullname).join(', ')}</div>
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
