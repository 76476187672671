import React, { useEffect, useState } from "react";
import './style.scss'
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "../../component/common/Button";
import PaginationCommon from "../../component/common/Pagination";
import ModalAddNewWorkPlan from "../../component/work_plan/ModalAddNewWorkPlan";
import TableWorkPlan from "../../component/work_plan/TableWorkPlan";
import { apiGetListWorkPlans, apiSaveWorkPlans } from "../../services/WorkPlan";
import { useSelector } from "react-redux";
import { getSiteId } from "../../utils/auth";
import { ToastContainer } from "react-toastify";
import { ToastFailed, ToastSuccess } from "../../component/common/Toast";
import { useEditing } from "../../hook/EdittingContext";
const items = [
    {
        title: 'Work plan',
    },
]

export default () => {
    const { isEditing, setIsEditing } = useEditing();

    const siteSelected = useSelector((state) => state.site).selectedSite?.siteId || (JSON.parse(getSiteId()))?.siteId;
    const [listWorkPlans, setListWorkPlans] = useState([])
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalRecord, setTotalRecord] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleInputSearch = (e) => {
        setPageIndex(1)
        setSearch(e.target.value)
    }

    const handleGetListWorkPlans = async (page = 1) => {
        let formParams = { search: search, siteId: siteSelected, currentPage: page, displayItem: pageSize }
        try {
            const response = await apiGetListWorkPlans(formParams)
            if (response?.header?.resultCode === "1000") {
                const dataConfig = response?.records?.listWorkPlans?.reports?.map((item, index) => {
                    return ({
                        ...item,
                        dataIndex: (pageIndex - 1) * pageSize + index + 1,
                        key: (pageIndex - 1) * pageSize + index + 1,
                    })
                })
                setListWorkPlans(dataConfig)
                setTotalRecord(response?.records?.listWorkPlans?.totalRecord)
            }
        } catch (e) {
            console.log(e);
            setListWorkPlans([])
        }

    }

    useEffect(() => {
        handleGetListWorkPlans(pageIndex)
    }, [pageIndex, pageSize, search, siteSelected])

    const handleSaveWorkPlans = async () => {
        try {
            setLoading(true)
            const response = await apiSaveWorkPlans({
                siteId: siteSelected,
                workPlans: listWorkPlans
            })
            if (response?.header?.resultCode === "1000") {
                ToastSuccess("Updated successfully!")
                handleGetListWorkPlans(pageIndex)
            }

        } catch (e) {
            console.log(e);
            ToastFailed("Some thing went wrong, please try again.")
        } finally {
            setLoading(false)
            setIsEditing(false)
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="work-plan_title" >
                <BreadcrumbHeader title={"Work plan"} items={items} />
            </div>
            <div className="content">
                <ModalAddNewWorkPlan
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setPageIndex={setPageIndex}
                    handleGetListWorkPlans={handleGetListWorkPlans}
                />
                <div className="report-page constr-volume-page p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="constr-volume-page_title">Work plan</div>
                        <div className="d-flex gap-2">
                            <div className="form-input">
                                <input placeholder="Search" value={search} onChange={handleInputSearch} maxLength={100} />
                                <div className="icon">
                                    <SearchOutlined />
                                </div>
                            </div>
                            <Button title={"Save"} BgColor={"#39AF01"} borderColor={"#39AF01"} width={"62px"} handleClick={handleSaveWorkPlans} loading={loading} />
                            <Button title={"Add"} icon={<PlusOutlined />} BgColor={"#1890FF"} borderColor={"#1890FF"} fontColor={"#ffffff"} handleClick={showModal} />
                        </div>
                    </div>
                    <div className="table-volume" style={{ flexGrow: 1 }}>
                        <TableWorkPlan listWorkPlans={listWorkPlans}
                            setListWorkPlans={setListWorkPlans}
                            setPageIndex={setPageIndex}
                            handleGetListWorkPlans={handleGetListWorkPlans}
                        />
                    </div>
                    <div className="text-end">
                        <PaginationCommon
                            totalRecord={totalRecord}
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            setPageSize={setPageSize} />
                    </div>
                </div>
            </div>
        </>
    );
};
