import React, { useEffect, useState } from "react";
import './style.scss'
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import TableSite from "../../component/site/TableSite";
import Button from "../../component/common/Button";
import ModalAddSite from "../../component/site/ModalAddSite";
import PaginationCommon from "../../component/common/Pagination";
import { apiGetListSites } from "../../services/Site";
import { ToastContainer } from "react-toastify";
import { Role } from "../../utils/Role";
import { useSelector } from "react-redux";
import ModalUploadSite from "../../component/site/ModalUploadSite";
import { Progress } from "antd";
const items = [
    {
        title: 'Site',
    },
    {
        title: 'Site List',
    },
]

export default () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalRecord, setTotalRecord] = useState(0);
    const [listData, setListData] = useState([])
    const [search, setSearch] = useState("")
    const userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;
    const [visibleUpload, setVisibleUpload] = useState(false);
    const progress = useSelector((state) => state.site)?.progress

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showModalImportSite = ()=> {
        setVisibleUpload(true)
    }

    const handleInputSearch = (e) => {
        setPageIndex(1);
        setSearch(e.target.value);
    }

    const handleGetListSites = async (page = 1) => {
        let formParams = { pageIndex: page, pageSize, textSearch: search }
        try {
            const response = await apiGetListSites(formParams)
            if (response?.header?.resultCode === "1000") {
                const dataConfig = response.records.listSite.sites.map((item, index) => {
                    return ({
                        ...item,
                        index: (pageIndex - 1) * pageSize + index + 1,
                        key: (pageIndex - 1) * pageSize + index + 1,
                    })
                })
                setListData(dataConfig)
                setTotalRecord(response?.records?.listSite?.totalRecord)
            }
        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {
        handleGetListSites(pageIndex)
    }, [pageIndex, pageSize, search])

    return (
        <>
            <ToastContainer />
            <BreadcrumbHeader title={"Site List"} items={items} />
            <div className="content">
                <ModalUploadSite visible={visibleUpload} setVisible={setVisibleUpload} handleGetListSites = {handleGetListSites} setPageIndex={setPageIndex}/>
                <ModalAddSite setPageIndex={setPageIndex} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleGetListSites={handleGetListSites} />
                <div className="report-page site-page p-4">
                    <div className="daily-report">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="total-report">{totalRecord} sites</div>
                            <div className="d-flex gap-2">
                                {progress && progress !== 100 && !visibleUpload ?
                                    <Progress percent={progress} status="active"  style={{width:"300px"}}/> : ""
                                }
                                <div className="form-input">
                                    <input placeholder="Search" value={search} onChange={handleInputSearch} maxLength={100} />
                                    <div className="icon">
                                        <SearchOutlined />
                                    </div>
                                </div>
                                {userRole === Role.Admin &&
                                <>
                                    <Button title={"Add"} icon={<PlusOutlined />} handleClick={showModal} />
                                    <Button title={"Import"} BgColor={"#39AF01"} borderColor={"#39AF01"} fontColor={"#fff"} handleClick={showModalImportSite}/>
                                </>
                                }
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <TableSite setPageIndex={setPageIndex} listData={listData} handleGetListSites={handleGetListSites} />
                        </div>
                        <div className="text-end">
                            <PaginationCommon totalRecord={totalRecord}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
