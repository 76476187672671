import React from "react";
import { Breadcrumb } from 'antd';

export const BreadcrumbHeader = ({ title, items }) => {

    return (
        <div className="beadcrumb-header">
            <Breadcrumb
                items={items}
            />
            <div className="title">{title}</div>
        </div>
    );
};
