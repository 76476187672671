export const Routes = {
    Management: { path: "/management" },

    ListReports: { path: "/daily-report/report-list" },
    NewReport: { path: "/daily-report/new-report" },
    Accomplishment: { path: "/daily-report/accomplishment" },
    WorkPlan: { path: "/work-plan" },
    ProgressReport: { path: "/progress-report" },

    Login: { path: "/" },

    ListSites: { path: "/site/all-site" },

    ListUsers: { path: "/user/all-users" },
    ListAdministrators: { path: "/user/administrator" },

    WorkItem: { path: "/master-data/work-item" },
    Manpower: { path: "/master-data/manpower" },
    Equipment: { path: "/master-data/equipment" },
}