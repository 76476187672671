import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "../../style.scss";
import Down from "../Down";
import { CalculatorOutlined, FormOutlined, MenuFoldOutlined, TableOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../../Routes";
import { Role } from "../../../../utils/Role";
import { useSelector } from "react-redux";
import { useEditing } from "../../../../hook/EdittingContext";

export const SidebarReportManagement = () => {
    const { isEditing, setIsEditing } = useEditing();
    const [activeLink, setActiveLink] = useState(null);
    const [activeSidebarItems, setActiveSidebarItems] = useState([]);
    const role = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith("/daily-report")) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "report"])]);
            if (path === Routes.NewReport.path) setActiveLink("new_report");
            if (path === Routes.ListReports.path) setActiveLink("list_reports");
            if (path === Routes.Accomplishment.path) setActiveLink("accomplishment");
        } else if (path === Routes.WorkPlan.path) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "work_plan"])]);
            setActiveLink("work_plan");
        }
        if (path === Routes.ProgressReport.path) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "progress_report"])]);
            setActiveLink("progress_report");
        }
    }, [location]);

    const toggleSidebarItem = (item) => {
        setActiveSidebarItems((prev) =>
            prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
        );
    };

    const handleNavigation = (path, linkName) => {
        if (isEditing) {
            Modal.confirm({
                title: "Warning",
                content: "Your changes have not been saved. Are you sure you want to leave this page?",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: () => {
                    setIsEditing(false);
                    setActiveLink(linkName);
                    navigate(path);
                },
            });
        } else {
            setActiveLink(linkName);
            navigate(path);
        }
    };

    const sidebarItems = [
        {
            label: "Report",
            icon: <FormOutlined />,
            name: "report",
            role: [Role.Admin, Role.Manage, Role.User],
            children: [
                { label: "Report list", path: Routes.ListReports.path, name: "list_reports", role: [Role.Admin, Role.Manage, Role.User] },
                { label: "New report", path: Routes.NewReport.path, name: "new_report", role: [Role.Admin, Role.Manage, Role.User] },
                { label: "Accomplishment", path: Routes.Accomplishment.path, name: "accomplishment", role: [Role.Admin, Role.Manage, Role.User] },
            ],
        },
        {
            label: "Work plan",
            icon: <TableOutlined />,
            role: [Role.Admin, Role.Manage, Role.User],
            path: Routes.WorkPlan.path,
            name: "work_plan",
        },
        {
            label: "Progress report",
            icon: <CalculatorOutlined />,
            role: [Role.Admin, Role.Manage, Role.User],
            path: Routes.ProgressReport.path,
            name: "progress_report",
        },
    ];

    return (
        <aside className="sidebar">
            <div className="h-100">
                <ul className="sidebar-nav">
                    {sidebarItems.map((item) =>
                        item.role.includes(role) ? (
                            <li key={item.name}>
                                {item.path ? (
                                    <div
                                        className={`sidebar-item ${activeLink === item.name ? "active" : ""}`}
                                        onClick={() => handleNavigation(item.path, item.name)}
                                    >
                                        {item.icon}
                                        <span>{item.label}</span>
                                    </div>
                                ) : (
                                    <div
                                        className={`sidebar-item ${activeSidebarItems.includes(item.name) ? "active" : ""}`}
                                        onClick={() => toggleSidebarItem(item.name)}
                                    >
                                        {item.icon}
                                        <div style={{ flexGrow: 1 }}>{item.label}</div>
                                        <div>
                                            <Down isOpen={activeSidebarItems.includes(item.name)} />
                                        </div>
                                    </div>
                                )}
                                {item.children && activeSidebarItems.includes(item.name) && (
                                    <ul className="list-unstyled">
                                        {item.children.map((subItem) =>
                                            subItem.role?.includes(role) ? (
                                                <div
                                                    className={`sidebar-link ${activeLink === subItem.name ? "active" : ""}`}
                                                    onClick={() => handleNavigation(subItem.path, subItem.name)}
                                                    key={subItem.name}
                                                >
                                                    {subItem.label}
                                                </div>
                                            ) : null
                                        )}
                                    </ul>
                                )}
                            </li>
                        ) : null
                    )}
                </ul>
                <div className="sidebar_bottom">
                    <MenuFoldOutlined style={{ fontSize: "16px" }} />
                </div>
            </div>
        </aside>
    );
};
