import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { apiEditUser } from "../../services/User";
import { useLocation } from "react-router-dom";
import { Routes } from "../../Routes";
import { Role } from "../../utils/Role";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { onBlurInputFormData } from "../../helps/InputData";
import { Status } from "../../utils/Status";

export default ({ setPageIndex, isModalOpen, setIsModalOpen, handleGetListUsers, userDetail, optionsSite }) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ ...userDetail, password: "" })

    const [errors, setErrors] = useState({
        userId: false,
        fullname: false,
        password: "",
        siteIds: false
    });
    const [errorUserId, setErrorUserId] = useState("")

    const location = useLocation();
    const currentRoute = location.pathname;
    let optionsRole = [];
    if (currentRoute === Routes.ListAdministrators.path) {
        optionsRole = [
            { label: "Sub-Admin", value: Role.Manage },
            { label: "Admin", value: Role.Admin }
        ];
    }
    if (currentRoute === Routes.ListUsers.path) {
        optionsRole = [{ label: "User", value: Role.User }];
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormData({
            userId: "",
            fullname: "",
            password: "",
            email: "",
            role: 2,
            status: 1,
            siteIds: []
        })
        setErrors({
            userId: false,
            fullname: false,
            siteIds: false
        });
    };

    useEffect(() => {
        if (isModalOpen) {
            if (userDetail?.siteIds?.length > 0) {
                let listSite = []
                if (userDetail?.siteIds[0]?.status === Status.Enable) {
                    listSite = [userDetail?.siteIds[0]?.siteId];
                } else {
                    listSite = [userDetail?.siteIds[0]?.siteName];
                }
                setFormData({
                    ...userDetail,
                    password: "",
                    siteIds: listSite
                })
            } else {
                setFormData({
                    ...userDetail,
                    password: "",
                    siteIds: []
                })
            }
        }
    }, [isModalOpen, userDetail])

    // const onChangeSelectRole = (value) => {
    //     setFormData({ ...formData, role: value })
    // };

    const onChangeSelectStatus = (value) => {
        setFormData({ ...formData, status: value })
    };

    const handleChangeInput = (e, name) => {
        const newFormData = { ...formData };
        newFormData[name] = e.target.value
        setFormData(newFormData)
        setErrors({ ...errors, [name]: false });
        if (name === "userId") {
            setErrorUserId("")
        }
    }

    const handleChangeSite = (value) => {
        setFormData({ ...formData, siteIds: [value] });
        setErrors({ ...errors, siteIds: false });
    }

    useEffect(() => {
        if (formData.role === Role.Admin) {
            setErrors({ ...errors, "siteIds": false });
        }
    }, [formData.role])

    const handleEditUser = async () => {
        const newErrors = {
            userId: !formData.userId.trim(),
            fullname: !formData.fullname.trim(),
            siteIds: formData.role === Role.Admin ? false : formData.siteIds.length === 0,
            password: ""
        };

        if (formData.password.length < 6 && formData.password !== "") {
            newErrors.password = "Password must be at least 6 characters."
        }
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        try {
            setLoading(true)
            const response = await apiEditUser({ ...formData, userId: formData.userId.trim(), fullname: formData.fullname.trim(), email: formData.email.trim() })
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListUsers()
                handleCancel()
                ToastSuccess("Account updated successfully.")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes("UserId is existed.")) {
                    ToastFailed("UserId already exits.");
                    setErrorUserId("UserId already exits.")
                } else {
                    ToastFailed("Account updated failed.")
                }
            } else {
                // Handle other unexpected errors
                console.log(error);
                ToastFailed("Account updated failed.")
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <Modal title={"Edit User"} open={isModalOpen} footer={null} className='modal-add-site'>
            <div className="site-add-data">
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>User ID <span className="text-danger ms-1">*</span></label>
                        <Input
                            disabled
                            value={formData.userId}
                            placeholder="Input user ID"
                            className={`w-100 ${errors.userId || errorUserId ? 'input-error' : ''}`}
                            maxLength={30}
                        />
                    </div>
                    {errors.userId && <div className="text-danger text-error">User ID is required field.</div>}
                    {errorUserId && <div className="text-danger text-error">{errorUserId}</div>}
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Password <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.password}
                            type="password"
                            placeholder="******"
                            className={`w-100 ${errors.password ? 'input-error' : ''}`}
                            onChange={(e) => handleChangeInput(e, "password")}
                            maxLength={100}
                        />
                    </div>
                    {errors.password && <div className="text-danger text-error">{errors.password}</div>}
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Name <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.fullname}
                            placeholder="Input full name"
                            className={`w-100 ${errors.fullname ? 'input-error' : ''}`}
                            onChange={(e) => handleChangeInput(e, "fullname")}
                            maxLength={50}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "fullname" })}
                        />
                    </div>
                    {errors.fullname && <div className="text-danger text-error">Name is required field.</div>}
                </div>
                <div className="d-flex gap-2">
                    <label>Email</label>
                    <Input
                        placeholder="Input Email"
                        className="w-100"
                        value={formData.email}
                        onChange={(e) => handleChangeInput(e, "email")}
                        maxLength={50}
                        onBlur={() => onBlurInputFormData({ formData, setFormData, field: "email" })}
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Role</label>
                    <Select
                        style={{ width: "135px" }}
                        value={formData.role}
                        optionFilterProp="label"
                        disabled
                        options={optionsRole}
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Status</label>
                    <Select
                        style={{ width: "135px" }}
                        value={formData.status}
                        optionFilterProp="label"
                        onChange={onChangeSelectStatus}
                        options={[
                            { value: 1, label: 'Enable' },
                            { value: 0, label: 'Disable' }
                        ]}
                    />
                </div>
                <div className="d-flex flex-column gap-2 mt-4">
                    <div className="d-flex gap-2">
                        <label>Site <span className="text-danger ms-1">*</span></label>
                        <Select
                            className={`w-100 ${errors.siteIds ? 'input-error' : ''}`}
                            showSearch
                            placeholder="Select site"
                            onChange={handleChangeSite}
                            options={optionsSite}
                            value={formData.role === Role.Admin ? "All sites" : formData.siteIds}
                            disabled={formData.role === Role.Admin}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>
                    {errors.siteIds && <div className="text-danger text-error">Site is required field.</div>}
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Save"} width={"62px"} handleClick={handleEditUser} loading={loading} />
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
