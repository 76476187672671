import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import { ReactComponent as IconPlus } from "../../assets/icons/icon-plus.svg";
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons';
const CustomPopconfirm = ({ onConfirm, onCancel, setIsEditting }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    // const handleVisibleChange = (newVisible, event) => {
    //     if (event && event.target.tagName !== "BUTTON") {
    //         return; // Không thay đổi trạng thái nếu click không phải từ nút
    //     }
    //     setVisible(newVisible);
    // };

    const handleConfirmClick = async () => {
        setLoading(true);
        const response = await onConfirm();
        if (response?.header?.resultCode === "1000") {
            setVisible(false);
            onCancel()
            setIsEditting(false)
        }
        setLoading(false);
    };

    const handleCancelClick = () => {
        onCancel()
        setVisible(false);
    };

    const handleClickX = ()=> {
        setVisible(false);
    }

    const content = (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', }} className='mt-3'>
            <Button onClick={handleCancelClick}>No</Button>
            <Button onClick={handleConfirmClick} type="primary" loading={loading} warning>Yes</Button>
        </div>
    );

    return (
        <Popover
            content={content}
            title={
                <div className='d-flex gap-2 align-items-start'>
                    <div className='mt-0'>
                        <ExclamationCircleFilled style={{ marginRight: 8, color: "#faad14" }} />
                    </div>
                    <div style={{width: "240px"}}>
                        Your changes have not been saved. Do you want to save them?
                    </div>
                        <CloseOutlined
                            style={{ cursor: "pointer", color: "#999" }}
                            onClick={handleClickX}
                        />
                </div>
                }
            trigger="click"
            visible={visible}
            onVisibleChange={(newVisible) => {
                setVisible(newVisible);
            }}
        >
            <Button icon={<IconPlus />} type="link" ></Button>
        </Popover>
    );
};

export default CustomPopconfirm;
