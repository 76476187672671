export function formatNumberSimple(number) {
    // Check if input is a number
    if (typeof number !== "number" || isNaN(number)) return "Input is not a number";

    // Convert number to string and separate integer and decimal parts
    let [integer, decimal] = number.toString().split(".");

    // Add comma to integer part
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Only display decimal part if present and different from "000"
    return decimal && parseInt(decimal) !== 0 ? `${integer}.${decimal}` : integer;
}
