const TIMESHEET_SITE = 'timesheet_site'
const TIMESHEET_USER = 'timesheet_userData'

export const ROLE = {
    Admin: 0,
    Manager: 1,
    User: 2
}

export function getSiteId() {
    return localStorage.getItem(TIMESHEET_SITE);
}

export function setSiteLocal(data) {
    return localStorage.setItem(TIMESHEET_SITE, JSON.stringify(data));
}

export function getUser() {
    return localStorage.getItem(TIMESHEET_USER);
}