import client from "../api/axiosClient";

const apiCreateWorkItem = (data) => {
    return client.post("/api/workitem/create", data);
};

const apiEditWorkItem = (data) => {
    return client.put("/api/workitem/update", data);
};

const apiDeleteWorkItem = (id) => {
    return client.delete(`/api/workitem/delete/${id}`);
};

const apiGetListWorkItems = ({ pageIndex, pageSize, textSearch, isGetDataWithNullSiteId, siteId }) => {
    return client.get(`/api/workitem/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&textSearch=${textSearch}&IsGetDataWithNullSiteId=${isGetDataWithNullSiteId}&SiteId=${siteId}`);
};

const apiDetailWorkItem = (id) => {
    return client.get(`/api/workitem/detail/${id}`);
};

const apiDetailWorkItemBySite = (siteId) => {
    return client.get(`/api/workitem/detailBySite/${siteId}`);
};

export {
    apiCreateWorkItem,
    apiEditWorkItem,
    apiDeleteWorkItem,
    apiGetListWorkItems,
    apiDetailWorkItem,
    apiDetailWorkItemBySite
};

