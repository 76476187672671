import React, { useEffect, useState } from "react";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import './style.scss'
import Button from "../../component/common/Button";
import { PlusOutlined } from "@ant-design/icons";
import TableDailyReport from "../../component/report/report_list/TableDailyReport";
import PaginationCommon from "../../component/common/Pagination";
import { exportProgressReport } from '../../../src/services/ProgressReport';
import { ToastContainer } from "react-toastify";
import { PAGESIZE } from "../../constants";
import { apiGetAllBySite } from "../../services/User";
import { Select } from "antd";
import { apiGetListReport, getLstLocationGroup } from "../../services/Report";
import { getSiteId, getUser, ROLE } from "../../utils/auth";
import { useNavigate } from 'react-router-dom';
import { Routes } from "../../Routes";
import { useSelector } from "react-redux";
import { apiDetailSiteUser } from "../../services/Site";
import { ToastFailed } from "../../component/common/Toast";

const items = [
    {
        title: 'Report',
    },
    {
        title: 'Report list',
    },
]
const pad2 = (n) => { return n < 10 ? '0' + n : n };

export default () => {
    let siteId = useSelector((state) => state.site).selectedSite?.siteId || JSON.parse(getSiteId())?.siteId;
    const user = JSON.parse(getUser());
    
    const [lstUserCbb, setLstUserCbb] = useState([]);
    const [listWorkItem, setWorkItem] = useState([]);
    const [listLocationCbb, setListLocationCbb] = useState([]);
    const [modelSearch, setModelSearch] = useState({
        UserId: null,
        Location: null,
        WorkItemId: null,
        CurrentPage: 1,
        DisplayItem: PAGESIZE,
        SiteId: siteId
    })
    const [totalRecord, setTotalRecord] = useState(0);
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(PAGESIZE)
    const [pageReports, setPageReports] = useState([]);

    const getListUser = async (siteId) => {
        try {
            const responseLstUser = await apiGetAllBySite(siteId)
            if (responseLstUser?.header?.resultCode === "1000") {
                setLstUserCbb(responseLstUser?.records?.listUser?.map(item => ({
                    value: item.userId,
                    label: item.fullname,
                })));
            }
        } catch (e) {
            console.log(e);
        }
    }
    const handleChangeUser = (value) => {
        setPageIndex(1)
        setModelSearch({ ...modelSearch, UserId: value });
    }

    const getListWorkItem = async (siteId) => {
        try {
            const responseDetailSite = await apiDetailSiteUser(siteId)
            if (responseDetailSite?.header?.resultCode === "1000") {
                setWorkItem(responseDetailSite?.records?.site?.workItems?.map(item => ({
                    value: item.workItemId,
                    label: item.name,
                })));
            }
        } catch (e) {
            console.log(e);
        }
    }
    const handleChangeWorkItem = (value) => {
        setPageIndex(1)
        setModelSearch({ ...modelSearch, WorkItemId: value })
    };
    let nameSite = useSelector((state) => state.site).selectedSite?.siteName || JSON.parse(getSiteId())?.siteName;
    const [loading, setLoading] = useState(false);
    const exportData = async () => {
            setLoading(true);
            var request = {
                userId: user.role === ROLE.User ? "": modelSearch.UserId,
                workItemId: modelSearch.WorkItemId,
                location: modelSearch.Location,
                SiteId: siteId
            }
            try {
                const response = await exportProgressReport(request);
                const url = URL.createObjectURL(new Blob([response], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                }));
                const link = document.createElement('a');
                link.href = url
                let date = new Date();
                // replace SiteName value
                let fileName = "Progress_" + nameSite + "_" + date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + ".xlsx";
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            } catch (e) {
                setLoading(false);
                if(e.message === "Request failed with status code 404"){
                    ToastFailed("There is no data to export.")
                }else{
                    ToastFailed("Some thing went wrong, please try again.");
                }
            }
    };

    const navigate = useNavigate()
    const addReport = () => {
        navigate(Routes.NewReport.path);
    }

    const getListLocation = async () => {
        const request = {
            UserId: modelSearch.UserId,
            SiteId: siteId
        }
        try {
            const responseLstLocation = await getLstLocationGroup(request)
            if (responseLstLocation?.header?.resultCode === "1000") {
                setListLocationCbb(responseLstLocation?.records?.listLocation?.map(item => ({
                    value: item,
                    label: item,
                })));
            }
        } catch (e) {
            console.log(e);
        }
    }
    const handleChangeLocation = (value) => {
        setPageIndex(1)
        setModelSearch({ ...modelSearch, Location: value });
    }

    const getListReport = async () => {
        try {
            var request = {
                UserId: modelSearch.UserId,
                Location: modelSearch.Location,
                WorkItemId: modelSearch.WorkItemId,
                CurrentPage: pageIndex,
                DisplayItem: pageSize,
                SiteId: siteId
            }
            const response = await apiGetListReport(request)
            if (response?.header?.resultCode === "1000") {
                setPageReports(response?.records?.listReports?.reports);
                setTotalRecord(response?.records?.listReports?.totalRecord);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (user.role == ROLE.User) {
            setModelSearch({ ...modelSearch, UserId: user.userId });
        }
    }, [user])

    useEffect(() => {
        getListLocation();
    }, [modelSearch.UserId, siteId])

    useEffect(() => {
        getListUser(siteId);
        getListWorkItem(siteId);
    }, [siteId]);

    useEffect(() => {
        setModelSearch({ ...modelSearch, CurrentPage: pageIndex, DisplayItem: pageSize });
        getListReport();
    }, [pageIndex, pageSize, modelSearch.WorkItemId, modelSearch.Location, modelSearch.UserId, siteId]);

    return (
        <>
            <ToastContainer />
            <BreadcrumbHeader title={"Report list"} items={items} />
            <div className="content">
                <div className="report-page p-4">
                    <div className="daily-report">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="total-report">{totalRecord} reports</div>
                            <div className="d-flex gap-2">
                                <div className="form-input">
                                    <Select style={{ width: "250px" }} showSearch options={lstUserCbb}
                                        placeholder="User"
                                        allowClear
                                        value={modelSearch.UserId}
                                        onChange={handleChangeUser}
                                        disabled={user.role == ROLE.User}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <div className="form-input">
                                    <Select style={{ width: "250px" }} showSearch options={listLocationCbb}
                                        placeholder="Location"
                                        allowClear
                                        value={modelSearch.Location}
                                        onChange={handleChangeLocation}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <div className="form-input">
                                    <Select style={{ width: "250px" }} showSearch options={listWorkItem}
                                        placeholder="Work item"
                                        allowClear
                                        value={modelSearch.WorkItemId}
                                        onChange={handleChangeWorkItem}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                                <Button title={"New Report"} icon={<PlusOutlined />} handleClick={addReport} />
                                <Button title={"Export"} BgColor={"#39AF01"} borderColor={"#39AF01"} handleClick={exportData} loading={loading}/>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <TableDailyReport pageReports={pageReports} getListReport={getListReport} />
                        </div>
                        <div className="text-end">
                            <PaginationCommon totalRecord={totalRecord}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
