import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";
import { loginSuccess } from "./actions/authAction";
import siteReducer from "./reducers/siteReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
});

// Use compose to combine middleware and Redux DevTools
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const savedIsLoggedIn = localStorage.getItem("timesheet_isLoggedIn");

if (savedIsLoggedIn === "true") {
  store.dispatch(loginSuccess());
}

export default store;
