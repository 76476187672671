import React, { useEffect, useState } from "react";
import './style.scss'
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "../../component/common/Button";
import TableManpower from "../../component/master_data/TableManpower";
import PaginationCommon from "../../component/common/Pagination";
import ModalAddNewManpower from "../../component/master_data/ModalAddNewManpower";
import { apiGetListManpowers } from "../../services/Manpower";
import { ToastContainer } from "react-toastify";
import { Role } from "../../utils/Role";
import { useSelector } from "react-redux";
const items = [
    {
        title: 'Master Data',
    },
    {
        title: 'Manpower',
    },
]

export default () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalRecord, setTotalRecord] = useState(0);
    const [listData, setListData] = useState([])
    const [search, setSearch] = useState("")
    const userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleInputSearch = (e) => {
        setPageIndex(1)
        setSearch(e.target.value)
    }

    const handleGetListManpowers = async (page = 1) => {
        const formParams = { pageIndex: page, pageSize, textSearch: search, isGetDataWithNullSiteId: false }
        try {
            const response = await apiGetListManpowers(formParams)
            if (response?.header?.resultCode === "1000") {
                const dataConfig = response?.records?.listManpowers?.manpowers.map((item, index) => {
                    return ({
                        ...item,
                        index: (pageIndex - 1) * pageSize + index + 1,
                        key: (pageIndex - 1) * pageSize + index + 1,
                    })
                })
                setListData(dataConfig)
                setTotalRecord(response?.records?.listManpowers?.totalRecord)
            }
        } catch (e) {
            console.log(e);
        }

    }

    useEffect(() => {
        handleGetListManpowers(pageIndex)
    }, [pageIndex, pageSize, search])

    return (
        <>
            <ToastContainer />
            <BreadcrumbHeader title={"Manpower"} items={items} />
            <div className="content">
                <ModalAddNewManpower setPageIndex={setPageIndex} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleGetListManpowers={handleGetListManpowers} />
                <div className="report-page setting-page p-4">
                    <div className="daily-report">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="total-report">{totalRecord} {totalRecord > 1 ? "Manpowers" : "Manpower"}</div>
                            <div className="d-flex gap-2">
                                <div className="form-input">
                                    <input placeholder="Search" value={search} onChange={handleInputSearch} maxLength={100} />
                                    <div className="icon">
                                        <SearchOutlined />
                                    </div>
                                </div>
                                {userRole === Role.Admin &&
                                    <Button title={"Add"} icon={<PlusOutlined />} handleClick={showModal} />
                                }
                            </div>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <TableManpower setPageIndex={setPageIndex} pageIndex={pageIndex} listData={listData} handleGetListManpowers={handleGetListManpowers} />
                        </div>
                        <div className="text-end">
                            <PaginationCommon totalRecord={totalRecord}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
