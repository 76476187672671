export const SELECTED_SITE = "SELECTED_SITE";
export const PROGRESS_IMPORT = "PROGRESS_IMPORT";
export const STATUS_IMPORT = "STATUS_IMPORT";

export const updateSelectedSiteReducer = (site) => ({
    type: SELECTED_SITE,
    payload: site
});

export const setProgressImportSite = (progress) => ({
    type: PROGRESS_IMPORT,
    payload: progress
});

export const setImportStatus = (status) => ({
    type: STATUS_IMPORT,
    payload: status
});