import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Table } from "@themesberg/react-bootstrap";
import { InputNumber, Select } from "antd";
import { ReactComponent as IconDelete } from "../../../assets/icons/icon-delete.svg";

export const TableEquipment = forwardRef(({ pageEquipments, setPageEquipments, lstEquimentCbb, isSubmitting }, ref) => {
    const validateEquipment = () => {
        let isValid = true;
        pageEquipments.forEach((item) => {
            if (!item.equipment) {
                isValid = false;
            }
        });
        return isValid;
    };

    useImperativeHandle(ref, () => ({
        validateEquipment
    }));

    const TableRow = ({ index, equipment, amount }) => {
        const [inputEquipment, setInputEquipment] = useState(equipment);
        const [inputAmount, setInputAmount] = useState(amount);

         // Hàm xóa dòng
        const handleDelete = (index) => {
            const newPageEquipments = [...pageEquipments];
            newPageEquipments.splice(index, 1);
            setPageEquipments(newPageEquipments);
        };
        
        // Hàm lưu số lượng
        const handleSaveAmount = () => {
            const newPageEquipments = [...pageEquipments];
            newPageEquipments[index].amount = inputAmount;
            setPageEquipments(newPageEquipments);
        };

        const handleSaveEquipment = (value) => {
            const newPageEquipments = [...pageEquipments];
            newPageEquipments[index].equipment = value;
            setPageEquipments(newPageEquipments);
            setInputEquipment(value);
        };

        // Danh sách các giá trị đã chọn cho tất cả các dòng, ngoại trừ dòng hiện tại
        const selectedEquipments = pageEquipments
            .filter((_, idx) => idx !== index)
            .map((item) => item.equipment);

        // Lọc `lstEquipmentCbb` để loại bỏ các giá trị đã chọn ở các dòng khác
        const availableOptions = lstEquimentCbb.filter(
            (option) => !selectedEquipments.includes(option.value)
        );

        const handleKeyPress = (event) => {
            const inputElement = event.target;
            const charCode = event.which ? event.which : event.keyCode;
            const newChar = String.fromCharCode(charCode);
            const cursorPosition = inputElement.selectionStart;
            const inputValue = event.target.value;
        
            // Tạo giá trị sau khi thêm ký tự mới vào
            const updatedValue = inputValue == "0" ? newChar : inputValue.slice(0, cursorPosition) + newChar + inputValue.slice(cursorPosition);
            const validInput = updatedValue.replace(/[,]/g, ''); // Loại bỏ dấu phẩy và dấu chấm để tính số ký tự hợp lệ
        
            // Kiểm tra nếu giá trị nhập vào vượt quá 1000
            if (parseFloat(validInput) > 1000 && charCode !== 8) { // 8 là mã của phím backspace
                event.preventDefault();
                return;
            }
        
            // Cho phép các phím từ 0 đến 9, phím điều khiển (backspace), dấu chấm
            if (
                (charCode >= 48 && charCode <= 57) || // Số từ 0-9
                charCode === 46 ||                   // Dấu chấm
                charCode === 8                       // Backspace
            ) {
                const [integerPart, decimalPart] = validInput.split(".");
        
                // Chỉ cho phép một dấu chấm
                if (charCode === 46 && inputValue.includes(".")) {
                    event.preventDefault();
                    return;
                }
        
                // Kiểm tra số chữ số sau dấu thập phân (chỉ được 1 chữ số)
                if (decimalPart && decimalPart.length > 3) {
                    event.preventDefault();
                    return;
                }
        
                // Kiểm tra số nguyên không vượt quá 1000 (tức là không được nhập vượt quá 1000)
                if (integerPart && parseInt(integerPart) > 1000) {
                    event.preventDefault();
                    return;
                }
        
            } else {
                // Chặn các ký tự không phải số, dấu chấm
                event.preventDefault();
            }
        };

        const formatValue = (value) => {
            // Định dạng phần nguyên với dấu phẩy
            const formattedIntegerPart = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');      
            return formattedIntegerPart;
        };

        return (
            <tr>
                <td>{index + 1}</td>
                <td>
                    <Select
                        className="w-100"
                        value={inputEquipment}
                        onChange={handleSaveEquipment}
                        showSearch
                        options={availableOptions}
                        placeholder="Select Equipment"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                    {!inputEquipment && isSubmitting && (
                        <div style={{ color: 'red', marginTop: '5px' }}>
                            {'Equipment is required field'}
                        </div>
                    )}
                </td>
                <td>
                    <InputNumber
                        value={inputAmount}
                        onChange={(value) => setInputAmount(value || 1)}
                        onBlur={handleSaveAmount}
                        min={1}
                        max={1000}
                        formatter={formatValue}
                        onKeyPress={handleKeyPress}
                        onCopy={(e) => e.preventDefault()}   // Chặn sao chép
                        onPaste={(e) => e.preventDefault()}  // Chặn dán
                        onCut={(e) => e.preventDefault()} 
                    />
                </td>
                <td>
                    <div className="delete" onClick={() => handleDelete(index)}>
                        <IconDelete />
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <Table responsive className="tabel table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
                <tr>
                    <th style={{ width: "50px" }}>#</th>
                    <th>Equipment</th>
                    <th style={{ width: "144px" }}>Amount</th>
                    <th style={{ width: "47px" }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {pageEquipments.map((order, index) => (
                    <TableRow
                        key={`page-equipment-${order.id}`}
                        {...order}
                        index={index}
                    />
                ))}
            </tbody>
        </Table>
    );
});