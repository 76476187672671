import client from "../api/axiosClient";

const apiCreateUser = (data) => {
    return client.post("/api/user/create", data);
};

const apiEditUser = (data) => {
    return client.put("/api/user/update", data);
};

const apiDeleteUser = (id) => {
    return client.delete(`/api/user/delete/${id}`);
};

const apiGetListUsers = ({ pageIndex, pageSize, textSearch, isAdmin, siteId }) => {
    return client.get(`/api/user/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&siteId=${siteId}&textSearch=${textSearch}&isAdmin=${isAdmin}`);
};

const apiGetListUsersBySite = ({ pageIndex, pageSize, siteId }) => {
    return client.get(`/api/user/listbysite?currentPage=${pageIndex}&DisplayItem=${pageSize}&siteId=${siteId}&role=2`);
};

const apiGetListManagers = ({ pageIndex, pageSize }) => {
    return client.get(`/api/user/list?currentPage=${pageIndex}&DisplayItem=${pageSize}&role=1`);
};

const apiDetailUser = (id) => {
    return client.get(`/api/user/detail/${id}`);
};

const apiGetAllBySite = (siteId) => {
    return client.get(`/api/user/getAllBySite/${siteId}`);
};

export {
    apiCreateUser,
    apiEditUser,
    apiDeleteUser,
    apiGetListUsers,
    apiDetailUser,
    apiGetListManagers,
    apiGetListUsersBySite,
    apiGetAllBySite
};

