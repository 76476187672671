import { useState } from 'react';
import { ToastFailed } from '../component/common/Toast';
import { apiItemIdExistInReport } from '../services/Site';

const useCheckIdExist = () => {
    const [loadingCheckId, setLoadingCheckId] = useState(false);

    const checkIdExist = async ({ siteId, entityName, masterDataId }) => {
        setLoadingCheckId(true);
        try {
            const response = await apiItemIdExistInReport({ siteId, entityName, masterDataId });
            return response;
        } catch (e) {
            console.log(e);
            ToastFailed("Something went wrong, please try again.");
            return null;
        } finally {
            setLoadingCheckId(false);
        }
    };

    return { loadingCheckId, checkIdExist };
};

export default useCheckIdExist;
