import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import TextArea from "antd/es/input/TextArea";
import { apiCreateManpower } from "../../services/Manpower";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { onBlurInputFormData } from "../../helps/InputData";
import { apiDetailSite } from "../../services/Site";

export default ({ setPageIndex, isModalOpen, setIsModalOpen, handleGetListManpowers, siteId, setIsOpenModalSettingSite, setDataDetail }) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        remark: ""
    })
    const [errors, setErrors] = useState({
        name: "",
    });

    const handleCancel = async() => {
        if (siteId) {
            const responseDetailSite = await apiDetailSite(siteId)
            if (responseDetailSite?.header?.resultCode === "1000") {
                setDataDetail(responseDetailSite?.records?.site)
            }
            setIsOpenModalSettingSite(true)
        }
        setIsModalOpen(false);
        setFormData({})
    };

    useEffect(() => {
        if (isModalOpen) {
            setFormData({
                name: "",
                remark: ""
            })
            setErrors({
                name: ""
            })
        }
    }, [isModalOpen])

    const handleChangeInput = (e, name) => {
        const newFormData = { ...formData };
        newFormData[name] = e.target.value
        setFormData(newFormData)
        setErrors({ ...errors, [name]: "" })
    }

    const handleCreateManpower = async () => {
        const newErrors = {
            name: formData.name.trim() ? "" : "Name is required field.",
        };
        setErrors(newErrors);
        if (!formData.name) {
            return
        }
        let newFormData = { ...formData }
        if (siteId) {
            newFormData = { ...formData, siteId }
        }
        try {
            setLoading(true)
            const response = await apiCreateManpower({ ...newFormData, name: formData.name.trim(), remark: formData.remark.trim() })
            if (response?.header?.resultCode === "1000") {
                if (siteId) {
                    const responseDetailSite = await apiDetailSite(siteId)
                    if (responseDetailSite?.header?.resultCode === "1000") {
                        setDataDetail(responseDetailSite?.records?.site)
                    }
                    setIsOpenModalSettingSite(true)
                } else {
                    setPageIndex(1)
                }
                handleCancel()
                handleGetListManpowers()
                ToastSuccess("Manpower created successfully.")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes("is exist.")) {
                    setErrors({ ...errors, name: "The manpower name already exists in the system; please choose a different name." })
                    ToastFailed("The manpower name already exists in the system; please choose a different name.");
                } else {
                    ToastFailed("Manpower created failed.")
                }
            } else {
                // Handle other unexpected errors
                ToastFailed("Something went wrong, please try again!");
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal title={"New manpower"} open={isModalOpen} footer={null} className='modal-add-new'>
            <div className="add-new-data">
                <div>
                    <div className="d-flex gap-2">
                        <label>Name <span className="text-danger ms-1">*</span></label>
                        <Input
                            placeholder="Input name"
                            className={`${errors.name ? 'input-error' : ''} w-100`}
                            value={formData.name}
                            onChange={(e) => handleChangeInput(e, "name")}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "name" })}
                            maxLength={50} />
                    </div>
                    {errors.name && <div className="text-danger text-error">{errors.name}</div>}
                </div>
                <div className="d-flex gap-2">
                    <label className="remark">Remark</label>
                    <TextArea rows={3}
                        placeholder="Input remark"
                        maxLength={500}
                        value={formData.remark}
                        onChange={(e) => handleChangeInput(e, "remark")}
                        onBlur={() => onBlurInputFormData({ formData, setFormData, field: "remark" })} />
                </div>
            </div>
            <div className="modal-add-new_footer">
                <Button title={"Save"} width={"62px"} handleClick={handleCreateManpower} loading={loading} />
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
