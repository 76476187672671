import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { apiDetailSite } from "../../services/Site";
import { useSelector } from "react-redux";
import { getSiteId } from "../../utils/auth";
import { apiCheckExistWorkPlan, apiSaveWorkPlans } from "../../services/WorkPlan";
import { ToastFailed, ToastSuccess } from "../common/Toast";

export default ({ isModalOpen, setIsModalOpen, handleGetListWorkPlans, setPageIndex }) => {
    const siteSelected = useSelector((state) => state.site).selectedSite?.siteId || (JSON.parse(getSiteId()))?.siteId;
    const [loading, setLoading] = useState(false)
    const [locationData, setLocationData] = useState({
        x: "",
        y: "",
        z: ""
    })
    const [optionsX, setOptionsX] = useState([])
    const [optionsY, setOptionsY] = useState([])
    const [optionsZ, setOptionsZ] = useState([])
    const [formData, setFormData] = useState({
        siteId: siteSelected,
        location: "",
        workItemId: undefined
    })
    const [optionsWorkItem, setOptionsWorkItem] = useState([])
    const [errors, setErrors] = useState({
        x: "",
        y: "",
        z: "",
        workItemId: ""
    })

    const handleCancel = () => {
        setIsModalOpen(false)
        setFormData({
            siteId: siteSelected,
            location: "",
            workItemId: undefined
        })
        setErrors({
            x: "",
            y: "",
            z: "",
            workItemId: ""
        })
        setLocationData({
            x: "",
            y: "",
            z: ""
        })
    }

    useEffect(() => {
        setFormData({ ...formData, location: Object.values(locationData).join("/") })
    }, [locationData])

    const handleChangeOptionLocation = (value, name) => {
        setLocationData({ ...locationData, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }
    const handleOnChangeWorkItem = (value) => {
        setFormData({ ...formData, workItemId: value })
        setErrors({ ...errors, workItemId: "" })
    }

    const handleGetSiteDetail = async () => {
        try {
            const response = await apiDetailSite(siteSelected)
            if (response?.header?.resultCode === "1000") {
                const data = response?.records?.site;
                // const newLocationData = { ...locationData }
                if (data?.x) {
                    const xArray = data.x.split("/")
                    const newOptions = xArray.map(item => ({
                        label: item,
                        value: item
                    }))
                    setOptionsX(newOptions)
                }else{
                    setOptionsX([])
                }
                if (data?.y) {
                    const yArray = data.y.split("/")
                    const newOptions = yArray.map(item => ({
                        label: item,
                        value: item
                    }))
                    setOptionsY(newOptions)
                }else{
                    setOptionsY([])
                }
                if (data?.z) {
                    const zArray = data.z.split("/")
                    const newOptions = zArray.map(item => ({
                        label: item,
                        value: item
                    }))
                    setOptionsZ(newOptions)
                }else{
                    setOptionsZ([])
                }

                let newOptionsWorkItem = []
                if (data?.workItems.length > 0) {
                    newOptionsWorkItem = data?.workItems.map((item) => ({
                        label: item.name,
                        value: item.workItemId,
                        unit: item.unit
                    }))
                }
                setOptionsWorkItem(newOptionsWorkItem)
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        handleGetSiteDetail()
    }, [siteSelected])

    const handleAddNewWorkPlan = async () => {

        const newErrors = {
            x: locationData.x ? "" : "X is required field.",
            y: locationData.y ? "" : "Y is required field.",
            z: locationData.z ? "" : "Z is required field.",
            workItemId: formData.workItemId ? "" : "Work item is required field."
        }
        setErrors(newErrors)
        if (Object.values(newErrors).some(error => error)) {
            return;
        }
        const locationSelected = Object.values(locationData).join("/")
        try {
            setLoading(true);
            const response = await apiCheckExistWorkPlan(formData)
            if (response?.header?.resultCode === "1000") {
                if (response?.records?.workPlan === "Ok") {
                    const workItem = optionsWorkItem.find(item => item.value === formData.workItemId)
                    const newWorkPlan = {
                        location: locationSelected,
                        workItemId: workItem.value,
                        workItemName: workItem.label,
                        designVolume: 0,
                        unit1: workItem.unit,
                        productionRate: 0,
                        unit2: `${workItem.unit}/day`,
                        operatingRatio: 0,
                        duration: 0,
                        productionRateAdjust: 0,
                        unit3: `${workItem.unit}/day`
                    }
                    try {
                        const responseWorkPlan = await apiSaveWorkPlans({
                            siteId: siteSelected,
                            workPlans: [newWorkPlan]
                        })
                        if (responseWorkPlan?.header?.resultCode === "1000") {
                            setPageIndex(1)
                            handleGetListWorkPlans()
                            handleCancel()
                            ToastSuccess("The new work plan has been created successfully.")
                        }
                    } catch (error) {
                        if (error.response) {
                            ToastFailed(error.response.data.header?.message);
                        } else {
                            console.log(error);
                        }
                    }
                } else {
                    ToastFailed("This work plan has already existed")
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal title={"New work plan"} open={isModalOpen} footer={null} className='modal-add-new-task'>
            <div className="task-add-data">
                <div className="d-flex flex-column gap-0">
                    <div className="d-flex gap-2">
                        <label className="mt-1">Location</label>
                        <div className="d-flex gap-2">
                            <Select
                                style={{ width: "138px" }}
                                showSearch
                                className={`${errors.x ? "input-error" : ""}`}
                                options={optionsX}
                                value={locationData.x ? locationData.x : undefined}
                                placeholder="Select X"
                                onChange={(value) => handleChangeOptionLocation(value, "x")}
                            />
                            <Select
                                style={{ width: "138px" }}
                                showSearch
                                className={`${errors.y ? "input-error" : ""}`}
                                options={optionsY}
                                value={locationData.y ? locationData.y : undefined}
                                placeholder="Select Y"
                                onChange={(value) => handleChangeOptionLocation(value, "y")}
                            />
                            <Select
                                style={{ width: "138px" }}
                                showSearch
                                className={`${errors.z ? "input-error" : ""}`}
                                options={optionsZ}
                                value={locationData.z ? locationData.z : undefined}
                                placeholder="Select Z"
                                onChange={(value) => handleChangeOptionLocation(value, "z")}
                            />
                        </div>
                        <div className="w-100 d-flex text-center justify-content-center">
                            {Object.values(locationData).join("")}
                        </div>
                    </div>
                    {(errors.x || errors.y || errors.z) &&
                        <div className="d-flex gap-2" style={{ marginLeft: "195px" }}>
                            <div className="text-danger">Location is required field.</div>
                            {/* <div className="text-danger" style={{ width: "138px" }}>{errors.y}</div>
                            <div className="text-danger" style={{ width: "138px" }}>{errors.z}</div> */}
                        </div>
                    }
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex gap-2">
                        <label className="mt-2">Work item</label>
                        <div>
                            <Select
                                style={{ width: "430px" }}
                                showSearch
                                className={`${errors.workItemId ? "input-error" : ""}`}
                                placeholder="Select work item"
                                onChange={handleOnChangeWorkItem}
                                value={formData.workItemId ? formData.workItemId : undefined}
                                options={optionsWorkItem}
                            />
                        </div>
                    </div>
                    {errors.workItemId && <div className="text-danger" style={{ marginLeft: "195px" }}>{errors.workItemId}</div>}
                </div>
            </div>
            <div className="modal-add-new-task_footer">
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
                <Button title={"Save"} width={"62px"} handleClick={handleAddNewWorkPlan} loading={loading} />
            </div>
        </Modal>
    );
};
