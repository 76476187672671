import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { Role } from "../../utils/Role";
import { Status } from "../../utils/Status";

export default ({ isModalOpen, setIsModalOpen, userDetail, optionsSite }) => {
    const [formData, setFormData] = useState({ ...userDetail, password: "123456" })

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormData({
            userId: "",
            fullname: "",
            password: "",
            email: "",
            role: 2,
            status: 1,
            siteIds: []
        })
    };

    useEffect(() => {
        if (isModalOpen) {
            if (userDetail?.siteIds?.length > 0) {
                const listSite = [userDetail?.siteIds[0]?.siteId];
                setFormData({
                    ...userDetail,
                    password: "123456",
                    siteIds: listSite
                })
            } else {
                setFormData({
                    ...userDetail,
                    password: "123456",
                    siteIds: []
                })
            }
        }
    }, [isModalOpen, userDetail])

    return (
        <Modal title={"Detail User"} open={isModalOpen} footer={null} className='modal-add-site'>
            <div className="site-add-data">
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>User ID</label>
                        <Input
                            value={formData.userId}
                            className={`w-100`}
                            maxLength={30}
                            disabled
                        />
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Password</label>
                        <Input
                            value={formData.password}
                            type="password"
                            className={`w-100`}
                            disabled
                        />
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Name</label>
                        <Input
                            value={formData.fullname}
                            className={`w-100`}
                            disabled
                        />
                    </div>
                </div>
                <div className="d-flex gap-2">
                    <label>Email</label>
                    <Input
                        className="w-100"
                        type="email"
                        value={formData.email}
                        disabled
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Role</label>
                    <Select
                        style={{ width: "135px" }}
                        value={formData.role}
                        optionFilterProp="label"
                        options={[
                            { label: "Admin", value: Role.Admin },
                            { label: "Sub-admin", value: Role.Manage },
                            { label: "User", value: Role.User }
                        ]}
                        disabled
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Status</label>
                    <Select
                        style={{ width: "135px" }}
                        value={formData.status}
                        optionFilterProp="label"
                        options={[
                            { label: "Enable", value: Status.Enable },
                            { label: "Disable", value: Status.Disable }
                        ]}
                        disabled
                    />
                </div>
                <div className="d-flex flex-column gap-2 mt-4">
                    <div className="d-flex gap-2">
                        <label>Site</label>
                        <Select
                            className={`w-100`}
                            showSearch
                            placeholder="Select site"
                            value={formData.role === Role.Admin ? "All sites" : formData.siteIds}
                            options={optionsSite}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
