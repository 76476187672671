import React from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LayoutSystemManagement } from './component/layout/private/system_management/LayoutSystemManagement';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { LoginPage } from './pages/login/Login';
import { LayoutPublic } from './component/layout/public/LayoutPublic';
import { ConstructionManagement } from './pages/constr_management';
import { LayoutReportManagement } from './component/layout/private/report_management/LayoutReportManagement';
import ReportList from './pages/report/ReportList';
import NewReport from './pages/report/NewReport';
import Site from './pages/site';
import UserManagement from './pages/user_management/User';
import WorkItem from './pages/master_data/WorkItem';
import Manpower from './pages/master_data/Manpower';
import Equipment from './pages/master_data/Equipment';
import Administrator from './pages/user_management/Administrator';
import { Routes } from './Routes';
import Accomplishment from './pages/report/Accomplishment';
import WorkPlan from './pages/work_plan';
import ProgressReport from './pages/report/ProgressReport';
import store from "./store";
import { Provider, useSelector } from "react-redux";
import { Role } from './utils/Role';
import NotFound from './pages/NotFound';

const PrivateRoute = ({ roles, children }) => {
    let isLoggedIn = useSelector((state) => state.auth.isLoggedIn) || JSON.parse(localStorage.getItem("timesheet_isLoggedIn"));
    let userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;

    if (!isLoggedIn) {
        return <Navigate to={Routes.Login.path} replace />;
    }

    if (roles && !roles.includes(userRole)) {
        return <p>You don't have permission to view this page.</p>;
    }

    return children || <Outlet />;
};

const RedirectLogin = ({ children }) => {
    let isLoggedIn = useSelector((state) => state.auth.isLoggedIn) || JSON.parse(localStorage.getItem("timesheet_isLoggedIn"));
    let userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;

    return isLoggedIn ?
        (userRole === 2 ? <Navigate to={Routes.ListReports.path} replace /> : <Navigate to={Routes.Management.path} replace />)
        : children;
};


const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <LayoutPublic />,
            errorElement: <NotFound />,
            children: [
                {
                    path: Routes.Login.path,
                    element: (
                        <RedirectLogin>
                            <LoginPage />
                        </RedirectLogin>
                    ),
                },
            ],
        },
        {
            path: "/",
            element: <LayoutSystemManagement />,
            errorElement: <NotFound />,
            children: [
                {

                    path: Routes.Management.path,
                    element:
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <ConstructionManagement />
                        </PrivateRoute>
                },
                {

                    path: Routes.ListSites.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <Site />
                        </PrivateRoute>
                    )

                },
                {
                    path: Routes.ListUsers.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <UserManagement />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.ListAdministrators.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin]}>
                            <Administrator />
                        </PrivateRoute>)
                },
                {
                    path: Routes.WorkItem.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <WorkItem />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.Manpower.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <Manpower />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.Equipment.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage]}>
                            <Equipment />
                        </PrivateRoute>
                    )
                },
            ],
        },
        {
            path: "/",
            element: <LayoutReportManagement />,
            errorElement: <NotFound />,
            children: [
                {
                    path: Routes.ListReports.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage, Role.User]}>
                            <ReportList />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.NewReport.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage, Role.User]}>
                            <NewReport />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.Accomplishment.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage, Role.User]}>
                            <Accomplishment />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.ProgressReport.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage, Role.User]}>
                            <ProgressReport />
                        </PrivateRoute>
                    )
                },
                {
                    path: Routes.WorkPlan.path,
                    element: (
                        <PrivateRoute roles={[Role.Admin, Role.Manage, Role.User]}>
                            <WorkPlan />
                        </PrivateRoute>
                    )
                },
            ],
        },
    ]);

    return (
        <Provider store={store}>
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <RouterProvider router={router} />
                </I18nextProvider>
            </React.StrictMode>
        </Provider>
    );
};

export default App;