export const FormatTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
}
export const truncateString = (str, maxLength) => {
    if (!str) {
        return "";
    } else {
        if (str.length <= maxLength) {
            return str;
        }
    }
    return str.substring(0, maxLength) + "...";
}