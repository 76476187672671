import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import IconEyeShow from "../../assets/icons/icon-eye-show.png";
import IconEyeHidden from "../../assets/icons/icon-eye-hidden.png";
import { apiChangePassword } from "../../services/Auth";
import './style.scss'
import { ToastFailed, ToastSuccess } from "../common/Toast";
export default ({ isModalOpen, setIsModalOpen }) => {
    const [loading, setLoading] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    })

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [errors, setErrors] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    const handleChangeInput = (e, name) => {
        const newFormData = { ...formData };
        newFormData[name] = e.target.value
        setFormData(newFormData)
        setErrors({ ...errors, [name]: "" })
        // if (name === "newPassword" || name === "confirmPassword") {
        //     if (formData.newPassword === formData.confirmPassword) {
        //         setErrors({ ...errors, confirmPassword: "" })
        //     } else {
        //         setErrors({ ...errors, confirmPassword: "New Password and Confirm new password do not match." })
        //     }
        // }
    }
    const handleShowPassword = (name) => {
        setIsShowPassword({ ...isShowPassword, [name]: !isShowPassword[name] })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setFormData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
        setErrors({
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        })
        setIsShowPassword({
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        })
    }

    const handleChangePassword = async () => {
        const newErrors = {
            currentPassword: formData.currentPassword ? "" : "Current password is required field.",
            newPassword: formData.newPassword ? "" : "New password is required field.",
            confirmPassword: formData.confirmPassword ? "" : "Confirm password is required field."
        };

        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        if (formData.newPassword.length < 6) {
            setErrors({ ...errors, newPassword: "Password must be at least 6 characters." })
            return;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            setErrors({ ...errors, confirmPassword: "New Password and Confirm new password do not match." })
            return
        }

        try {
            setLoading(true)
            const response = await apiChangePassword(formData)
            if (response?.header?.resultCode === "1000") {
                handleCancel()
                ToastSuccess("The password has been changed successfully.")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.errors?.message?.includes("Old password does not match!")) {
                    setErrors({ ...errors, confirmPassword: "Password is incorrect" })
                    ToastFailed("Password is incorrect");
                } else {
                    ToastFailed("The password has been changed failed.")
                }

            } else {
                // Handle other unexpected errors
                ToastFailed("Something went wrong, please try again!");
            }
        } finally {
            setLoading(false)
        }

    }

    return (
        <Modal title={"Change password"} open={isModalOpen} footer={null} className='modal-change-password'>
            <div className="site-add-data">
                <div className="d-flex flex-column gap-2">
                    <div>
                        <div className="d-flex gap-2">
                            <label style={{ minWidth: "140px" }} className="d-flex gap-1"><span>Current password</span><span className="text-danger">*</span></label>
                            <div className="w-100 d-flex align-items-center form-input-password">
                                <Input
                                    value={formData.currentPassword}
                                    type={isShowPassword.currentPassword ? "text" : "password"}
                                    placeholder="Current password"
                                    className={`w-100`}
                                    onChange={(e) => handleChangeInput(e, "currentPassword")}
                                    maxLength={100}
                                />
                                <img
                                    onClick={() => { handleShowPassword("currentPassword") }}
                                    src={isShowPassword.currentPassword ? IconEyeShow : IconEyeHidden}
                                    alt="icon"
                                    width={20}
                                    height={20} />
                            </div>
                        </div>
                        {errors.currentPassword && <div className="text-danger text-error">{errors.currentPassword}</div>}
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div>
                        <div className="d-flex gap-2">
                            <label style={{ minWidth: "140px" }} className="d-flex gap-1"><span>New password</span><span className="text-danger">*</span></label>
                            <div className="w-100 d-flex align-items-center form-input-password">
                                <Input
                                    value={formData.newPassword}
                                    type={isShowPassword.newPassword ? "text" : "password"}
                                    placeholder="New password"
                                    className={`w-100`}
                                    onChange={(e) => handleChangeInput(e, "newPassword")}
                                    maxLength={100}
                                />
                                <img
                                    onClick={() => { handleShowPassword("newPassword") }}
                                    src={isShowPassword.newPassword ? IconEyeShow : IconEyeHidden}
                                    alt="icon"
                                    width={20}
                                    height={20} />
                            </div>
                        </div>
                        {errors.newPassword && <div className="text-danger text-error">{errors.newPassword}</div>}
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <div>
                        <div className="d-flex gap-2">
                            <label style={{ minWidth: "140px" }} className="d-flex gap-1 text-end"><span>Confirm new password</span><span className="text-danger">*</span></label>
                            <div className="w-100 d-flex align-items-center form-input-password">
                                <Input
                                    value={formData.confirmPassword}
                                    type={isShowPassword.confirmPassword ? "text" : "password"}
                                    placeholder="Confirm new password"
                                    className={`w-100`}
                                    onChange={(e) => handleChangeInput(e, "confirmPassword")}
                                    maxLength={100}
                                />
                                <img
                                    onClick={() => { handleShowPassword("confirmPassword") }}
                                    src={isShowPassword.confirmPassword ? IconEyeShow : IconEyeHidden}
                                    alt="icon"
                                    width={20}
                                    height={20} />
                            </div>
                        </div>
                        {errors.confirmPassword && <div className="text-danger text-error">{errors.confirmPassword}</div>}
                    </div>
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
                <Button title={"Save"} width={"62px"} handleClick={handleChangePassword} loading={loading} />
            </div>
        </Modal>
    );
};
