import { Outlet, useLocation } from "react-router-dom";
import { HeaderLogin } from "./HeaderLogin";
export const LayoutPublic = () => {

    const location = useLocation();
    const path = location.pathname;

    return (
        <div style={{ flexDirection: "column", position: "relative", minHeight: "100vh", height: "auto" }} className="d-flex">
            <HeaderLogin />
            <div className="d-flex">
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
