import React, { useEffect, useState } from "react";
import './style.scss'
import Button from "../../component/common/Button";
import TimesSheetBrand from "../../assets/images/timesheet_brand2.png"
import { Select, Spin } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../Routes";
import { Role } from "../../utils/Role";
import { apiGetListSitesEnable } from "../../services/Site";
import { useDispatch } from "react-redux";
import { updateSelectedSiteReducer } from "../../store/actions/siteAction";
import { getUser, setSiteLocal } from "../../utils/auth";

export const ConstructionManagement = () => {
    const [options, setOptions] = useState([])
    const [optionSelected, setOptionSelected] = useState("")
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const onChange = (value, option) => {
        setOptionSelected(value)
        const newSite = {
            siteId: option.value,
            siteName: option.label
        }
        dispatch(updateSelectedSiteReducer(newSite))
    };

    const navigate = useNavigate()
    const userId = JSON.parse(getUser())?.userId;
    const role = JSON.parse(getUser())?.role;

    const handleSite = async () => {
        if (userId) {
            try {
                setLoading(true)
                const response = await apiGetListSitesEnable({ pageIndex: 1, pageSize: 10000, textSearch: "" })
                if (response?.header?.resultCode === "1000") {
                    const listSites = response.records.listSite.sites;
                    const newOptions = listSites.map((item) => ({
                        label: item.siteName,
                        value: item.siteId
                    }))
                    setOptions(newOptions)
                }
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false)
            }
        }
    }
    useEffect(() => {
        handleSite()
    }, [])
    useEffect(() => {
        if (options.length > 0) {
            setOptionSelected(options[0].value)
            const newSite = {
                siteId: options[0].value,
                siteName: options[0].label
            }
            dispatch(updateSelectedSiteReducer(newSite))
        }
    }, [options])

    const handleClickReportManagement = () => {
        if (optionSelected) {
            navigate(Routes.ListReports.path)
        }
    }

    useEffect(() => {
        if (optionSelected) {
            const site = options.find(item => item.value === optionSelected)
            const newSite = {
                siteId: site.value,
                siteName: site.label
            }
            setSiteLocal(newSite);
        }
    }, [optionSelected])
    return (
        <div className="management-page">
            <div className="brand">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={TimesSheetBrand} height={120} />
                </div>
            </div>
            <div className="form-setting">
                <div className="form-setting_title">
                    <span>Construction Management</span>
                </div>
                <Spin spinning={loading}>
                    <Select
                        showSearch
                        className="w-100"
                        placeholder="Select a site"
                        optionFilterProp="label"
                        onChange={onChange}
                        options={options}
                        value={optionSelected}
                    />
                </Spin>
                <Button title={"Report Management"} handleClick={handleClickReportManagement} />
                <div className="devide"></div>
                {(role === Role.Admin || role === Role.Manage)
                    &&
                    <Link to={Routes.ListSites.path}>
                        <Button title={"System Management"} />
                    </Link>
                }
            </div>
        </div>
    );
};