import client from "../api/axiosClient";

const exportProgressReport = async (request) => {
    return await client.post('/api/report/progressExport', request, {
        responseType: 'blob',
    });
};

export {
    exportProgressReport,
}
