import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import TextArea from "antd/es/input/TextArea";
import { Status } from "../../utils/Status";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { apiCreateSite } from "../../services/Site";
import { onBlurInputFormData } from "../../helps/InputData";

export default ({ setPageIndex, isModalOpen, setIsModalOpen, handleGetListSites }) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        siteId: "",
        siteName: "",
        remark: "",
        status: Status.Enable
    })
    const [errors, setErrors] = useState({
        siteId: "",
        siteName: "",
    });

    const handleChangeInput = (e, name) => {
        if (name === "siteId") {
            const trimmedValue = e.target.value.replace(/\s+/g, "");
            setFormData({ ...formData, siteId: trimmedValue });
        } else {
            const newFormData = { ...formData };
            newFormData[name] = e.target.value
            setFormData(newFormData)
        }
        setErrors({ ...errors, [name]: "" })
    }

    const onChangeSelect = (value) => {
        setFormData({ ...formData, status: value })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormData({})
        setErrors({
            siteId: "",
            siteName: "",
        })
    };

    useEffect(() => {
        if (isModalOpen) {
            setFormData({
                siteId: "",
                siteName: "",
                remark: "",
                status: Status.Enable
            })
        }
    }, [isModalOpen])

    const handleCreateSite = async () => {
        const newErrors = {
            siteId: formData.siteId.trim() ? "" : "Site ID is required field.",
            siteName: formData.siteName.trim() ? "" : "Project name is required field.",
        };
        setErrors(newErrors);
        if (!formData.siteId || !formData.siteName) {
            return
        }

        try {
            setLoading(true)
            const response = await apiCreateSite({ ...formData, siteId: formData.siteId.trim(), siteName: formData.siteName.trim() })
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListSites()
                handleCancel()
                ToastSuccess("The site has been created successfully.")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes(`SiteId ${formData.siteId} is exist.`)) {
                    ToastFailed("This Site ID already exists. Please use another ID");
                    setErrors({ ...errors, siteId: "This Site ID already exists. Please use another ID" })
                } else if (error.response.data.header?.message?.includes(`Site name ${formData.siteName} is exist.`)) {
                    ToastFailed("The project name already exists in the system; please choose a different name.");
                    setErrors({ ...errors, siteName: "The project name already exists in the system; please choose a different name." })
                } else {
                    ToastFailed("The site has been created fail.")
                }
            } else {
                // Handle other unexpected errors
                console.log(error);
                ToastFailed("Some thing went wrong, please try again.")
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal title={"Add Site"} open={isModalOpen} footer={null} className='modal-add-site'>
            <div className="site-add-data">
                <div>
                    <div className="d-flex gap-2">
                        <label>Site ID <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.siteId}
                            placeholder="Input Site ID"
                            className={`${errors.siteId ? 'input-error' : ''} w-100`}
                            onChange={(e) => handleChangeInput(e, "siteId")}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "siteId" })}
                            maxLength={50} />
                    </div>
                    {errors.siteId && <div className="text-danger text-error">{errors.siteId}</div>}
                </div>
                <div>
                    <div className="d-flex gap-2">
                        <label>Project name <span className="text-danger ms-1">*</span></label>
                        <Input
                            placeholder="Input project name"
                            value={formData.siteName}
                            onChange={(e) => handleChangeInput(e, "siteName")}
                            className={`${errors.siteName ? 'input-error' : ''} w-100`}
                            maxLength={100}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "siteName" })}
                        />
                    </div>
                    {errors.siteName && <div className="text-danger text-error">{errors.siteName}</div>}
                </div>
                <div className="d-flex gap-2">
                    <label className="remark">Remark</label>
                    <TextArea rows={3}
                        placeholder="Input remark"
                        maxLength={500}
                        value={formData.remark}
                        onChange={(e) => handleChangeInput(e, "remark")}
                        onBlur={() => onBlurInputFormData({ formData, setFormData, field: "remark" })}
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Status</label>
                    <Select
                        style={{ width: "135px" }}
                        value={formData.status}
                        optionFilterProp="label"
                        onChange={onChangeSelect}
                        options={[
                            {
                                value: Status.Enable,
                                label: "Enable"
                            },
                            {
                                value: Status.Disable,
                                label: "Disable",
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Save"} width={"62px"} handleClick={handleCreateSite} loading={loading} />
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
