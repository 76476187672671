import React, { useEffect, useState } from "react";
import "../../style.scss";
import Down from "../Down";
import { ControlOutlined, MenuFoldOutlined, TableOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../../../../Routes";
import { Role } from "../../../../utils/Role";
import { useSelector } from "react-redux";

const SidebarSystemManagement = () => {
    const [activeSidebarItems, setActiveSidebarItems] = useState([]); // Track multiple active items
    const [activeLink, setActiveLink] = useState(null);
    const role = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith("/master-data")) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "master_data"])]);
            if (path === Routes.WorkItem.path) setActiveLink("work_item");
            if (path === Routes.Manpower.path) setActiveLink("manpower");
            if (path === Routes.Equipment.path) setActiveLink("equipment");
        } else if (path.startsWith("/user")) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "user"])]);
            if (path === Routes.ListAdministrators.path) setActiveLink("administrator");
            if (path === Routes.ListUsers.path) setActiveLink("all_users");
        } else if (path === Routes.ListSites.path) {
            setActiveSidebarItems((prev) => [...new Set([...prev, "site"])]);
            setActiveLink("site");
        }
    }, [location]);

    const toggleSidebarItem = (item) => {
        setActiveSidebarItems((prev) =>
            prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
        );
    };

    const sidebarItems = [
        {
            label: "Site",
            icon: <TableOutlined />,
            role: [Role.Admin, Role.Manage],
            path: Routes.ListSites.path,
            name: "site",
        },
        {
            label: "User",
            icon: <UserOutlined />,
            name: "user",
            role: [Role.Admin, Role.Manage],
            children: [
                { label: "Administrator", path: Routes.ListAdministrators.path, name: "administrator", role: [Role.Admin] },
                { label: "User", path: Routes.ListUsers.path, name: "all_users", role: [Role.Admin, Role.Manage] },
            ],
        },
        {
            label: "Master Data",
            icon: <ControlOutlined />,
            name: "master_data",
            role: [Role.Admin, Role.Manage],
            children: [
                { label: "Work Item", path: Routes.WorkItem.path, name: "work_item", role: [Role.Admin, Role.Manage] },
                { label: "Manpower", path: Routes.Manpower.path, name: "manpower", role: [Role.Admin, Role.Manage] },
                { label: "Equipment", path: Routes.Equipment.path, name: "equipment", role: [Role.Admin, Role.Manage] },
            ],
        },
    ];

    return (
        <aside className="sidebar">
            <div className="h-100">
                <ul className="sidebar-nav">
                    {sidebarItems.map((item) =>
                        item.role.includes(role) ? (
                            <li key={item.name}>
                                {item.path ? (
                                    <Link to={item.path}>
                                        <div
                                            className={`sidebar-item ${activeLink === item.name ? "active" : ""}`}
                                            onClick={() => {
                                                setActiveLink(item.name);
                                            }}
                                        >
                                            {item.icon}
                                            <span>{item.label}</span>
                                        </div>
                                    </Link>
                                ) : (
                                    <div
                                        className={`sidebar-item ${activeSidebarItems.includes(item.name) ? "active" : ""}`}
                                        onClick={() => toggleSidebarItem(item.name)}
                                    >
                                        {item.icon}
                                        <div style={{ flexGrow: 1 }}>{item.label}</div>
                                        <div>
                                            <Down isOpen={activeSidebarItems.includes(item.name)} />
                                        </div>
                                    </div>
                                )}
                                {item.children && activeSidebarItems.includes(item.name) && (
                                    <ul className="list-unstyled">
                                        {item.children.map((subItem) =>
                                            subItem.role?.includes(role) ? (
                                                <Link to={subItem.path} key={subItem.name}>
                                                    <li
                                                        className={`sidebar-link ${activeLink === subItem.name ? "active" : ""}`}
                                                        onClick={() => setActiveLink(subItem.name)}
                                                    >
                                                        {subItem.label}
                                                    </li>
                                                </Link>
                                            ) : null
                                        )}
                                    </ul>
                                )}
                            </li>
                        ) : null
                    )}
                </ul>
                <div className="sidebar_bottom">
                    <MenuFoldOutlined style={{ fontSize: "16px" }} />
                </div>
            </div>
        </aside>
    );
};

export default SidebarSystemManagement;
