import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from "./translation/en.json"
import viTranslation from "./translation/vi.json"
import jaTranslation from "./translation/ja.json"
// config i18n
i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslation
        },
        vi: {
            translation: viTranslation
        },
        ja: {
            translation: jaTranslation
        }
    },
    lng: 'en', //default language
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;