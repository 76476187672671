import React, {  useState } from 'react';
import {  Table } from 'antd';
import ModalDetailSite from './ModalDetailSite';
import ModalMasterData from './ModalMasterData';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
    horizontalListSortingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';
import { ReactComponent as IconEdit } from "../../assets/icons/icon-edit.svg";
import { ReactComponent as IconEye } from "../../assets/icons/icon-eye.svg";
import { ReactComponent as IconSetting } from "../../assets/icons/icon-setting.svg";
import { ReactComponent as IconDownload } from "../../assets/icons/icon-download.svg";
import ModalEditSite from './ModalEditSite';
import { FormatTime } from '../../utils/FormatTime';
import { apiBackupSite, apiDeleteSite, apiDetailSite } from '../../services/Site';
import { ToastFailed, ToastSuccess } from '../common/Toast';
import { Role } from '../../utils/Role';
import { Status } from '../../utils/Status';
import { useSelector } from 'react-redux';
import CustomPopconfirm from './CustomPopConfirm';

const TableSite = ({ setPageIndex, listData, handleGetListSites }) => {
    const userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [isModalOpenView, setIsModalOpenView] = useState(false);
    const [isModalOpenMasterData, setIsModalOpenMasterData] = useState(false);
    const [dataDetail, setDataDetail] = useState({})
    const [siteId, setSiteId] = useState("")

    const handleGetDetailSite = async (id) => {
        try {
            const response = await apiDetailSite(id)
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    const handleDeleteSite = async (id) => {
        try {
            const response = await apiDeleteSite(id)
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListSites()
                ToastSuccess("Site deleted sucessfully.")
            }
        } catch (e) {
            console.log(e);
            ToastFailed("Site deleted failed.")
        }
    }


    const showModalEdit = async (id) => {
        const responseData = await handleGetDetailSite(id)
        if (responseData?.header?.resultCode === "1000") {
            setDataDetail(responseData?.records?.site)
            setIsModalOpenEdit(true);
        }
    };

    const showModalView = async (id) => {
        const responseData = await handleGetDetailSite(id)
        if (responseData?.header?.resultCode === "1000") {
            setDataDetail(responseData?.records?.site)
            setIsModalOpenView(true);
        }
    }

    const showModalMasterData = async (id) => {
        const responseData = await handleGetDetailSite(id)
        if (responseData?.header?.resultCode === "1000") {
            setDataDetail(responseData?.records?.site)
            setSiteId(id)
            setIsModalOpenMasterData(true);
        }
    };

    const pad2 = (n) => { return n < 10 ? '0' + n : n };
    const handleBackupSite = async (id, siteName)=>{
        let date = new Date();

        try{
            const response = await apiBackupSite({siteId: id})
                const url = URL.createObjectURL(new Blob([response], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                }))
                const link = document.createElement('a');
                link.href = url;
                let fileName = "Backup" + "_" + siteName + "_" + date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + ".xlsx";
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
        }catch(e){
            console.log(e);
        }
    }

    const baseColumns = [
        {
            title: '#',
            dataIndex: 'index',
        },
        {
            title: 'Site ID',
            dataIndex: 'siteId',
        },
        {
            title: 'Project Name',
            dataIndex: 'siteName',
        },
        // {
        //     title: 'Remark',
        //     dataIndex: 'remark',
        // },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (text) => (
                <div>
                    {FormatTime(text)}
                </div>
            ),
        },
        {
            title: 'Updated by',
            dataIndex: 'lastModifiedBy',
            render: (text) => (
                <div>
                    {text ? text : "--"}
                </div>
            ),
        },
        {
            title: 'Updated date',
            dataIndex: 'updatedAt',
            render: (text) => (
                <div>
                    {FormatTime(text)}
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => <div className='d-flex gap-2'>
                <div className={`mt-2 ${text === 1 ? "enable" : "disable"}`}></div>
                <div>{text === 1 ? "Enable" : "Disable"}</div>
            </div>,
        },
        // {
        //     title: 'Manager',
        //     dataIndex: 'manager',
        // },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div className='d-flex px-2 gap-4 align-items-center'>
                    <div onClick={() => showModalView(record.siteId)}
                        style={{
                            opacity: record.status !== Status.Disable ? 1 : 0.2,
                            cursor: record.status !== Status.Disable ? "pointer" : "not-allowed",
                            pointerEvents: record.status !== Status.Disable ? "auto" : "none",
                        }}
                    ><IconEye /></div>
                    {userRole === Role.Admin && <div onClick={() => showModalEdit(record.siteId)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEdit /></div>}
                    <div
                        onClick={() => showModalMasterData(record.siteId)}
                        style={{
                            opacity: record.status !== Status.Disable ? 1 : 0.2,
                            cursor: record.status !== Status.Disable ? "pointer" : "not-allowed",
                            pointerEvents: record.status !== Status.Disable ? "auto" : "none",
                        }}
                    >
                        <IconSetting />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={()=> {handleBackupSite(record.siteId, record.siteName)}}><IconDownload /></div>
                    {userRole === Role.Admin &&
                        <CustomPopconfirm
                            onConfirm={() => handleDeleteSite(record.siteId)} // Hàm xử lý khi nhấn Confirm
                            onCancel={() => console.log("Canceled")} // Hàm xử lý khi nhấn Cancel
                            onBackup={() => handleBackupSite(record.siteId, record.siteName)} // Hàm xử lý khi nhấn Backup
                        />
                    }

                </div>
            ),
        },
    ];

    const [dragIndex, setDragIndex] = useState({
        active: -1,
        over: -1,
    });

    const [columns, setColumns] = useState(() =>
        baseColumns.map((column) => ({
            ...column,
            key: column.dataIndex,
            onHeaderCell: () => ({
                id: column.dataIndex,
            }),
            onCell: () => ({
                id: column.dataIndex,
            }),
        })),
    );
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
            collisionDetection={closestCenter}
        >
            <ModalDetailSite
                isModalOpen={isModalOpenView}
                setIsModalOpen={setIsModalOpenView}
                dataDetail={dataDetail} />
            <ModalEditSite
                setPageIndex={setPageIndex}
                isModalOpen={isModalOpenEdit}
                dataDetail={dataDetail}
                handleGetListSites={handleGetListSites}
                setIsModalOpen={setIsModalOpenEdit} />
            <ModalMasterData
                isModalOpen={isModalOpenMasterData}
                setIsModalOpen={setIsModalOpenMasterData}
                siteId={siteId}
                dataDetail={dataDetail}
                setDataDetail={setDataDetail} />
            <SortableContext items={columns.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                    <Table
                        rowKey="key"
                        columns={columns}
                        dataSource={listData}
                    />
            </SortableContext>
            <DragOverlay>
                <th
                    style={{
                        backgroundColor: 'gray',
                        padding: 16,
                    }}
                >
                    {columns[columns.findIndex((i) => i.key === dragIndex.active)]?.title}
                </th>
            </DragOverlay>
        </DndContext>
    );
};
export default TableSite;