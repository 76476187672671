import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import { ReactComponent as IconDelete } from "../../assets/icons/icon-delete.svg";
import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
const CustomPopconfirm = ({ onConfirm, onCancel, onBackup }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    const handleConfirmClick = async () => {
        setLoading(true);
        await onConfirm();
        setLoading(false);
        setVisible(false);
    };

    const handleCancelClick = () => {
        setVisible(false);
        onCancel();
    };

    const content = (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', }} className='mt-3'>
            <Button onClick={handleCancelClick}>Cancel</Button>
            <Button onClick={onBackup} className='btn-backup'>Backup</Button>
            <Button onClick={handleConfirmClick} type="primary" loading={loading} warning>Confirm</Button>
        </div>
    );

    return (
        <Popover
            content={content}
            title={
                <span>
                    <ExclamationCircleFilled style={{ marginRight: 8, color: "#faad14" }} />
                    Are you sure delete this Site?
                </span>
            }
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <Button icon={<IconDelete />} type="link" ></Button>
        </Popover>
    );
};

export default CustomPopconfirm;
