import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { InputNumber } from "antd";
import { useEditing } from "../../../hook/EdittingContext";

export  const TableRow = (props) => {
        const { isEditing, setIsEditing } = useEditing();
        const { index, date, location, workItem, accomplishment, unit, handleUpdateData, volumeData, SetVolumeData } = props;
        const [inputAmount, setInputAmount] = useState(accomplishment)
        
        const handleChangeAmount = (value) => {
            const inputValue = value || 0; // Đảm bảo input là số hoặc 0 nếu rỗng
            setInputAmount(inputValue);
            setIsEditing(true)
            const newVolumeData = [...volumeData];
            newVolumeData[index].accomplishment = value;
            SetVolumeData(newVolumeData);
            handleUpdateData(newVolumeData[index]);
        };

        const handleKeyPress = (event) => {
            const inputElement = event.target;
            const charCode = event.which ? event.which : event.keyCode;
            const newChar = String.fromCharCode(charCode);
            const cursorPosition = inputElement.selectionStart;
            const inputValue = event.target.value;

            const updatedValue = inputValue == "0" ? newChar : inputValue.slice(0, cursorPosition) + newChar + inputValue.slice(cursorPosition);
            const validInput = updatedValue.replace(/[,]/g, ''); // Loại bỏ dấu phẩy và dấu chấm để tính số ký tự hợp lệ

            // Kiểm tra nếu tổng số ký tự hợp lệ đã nhập không vượt quá 14 ký tự
            if (validInput.length > 14 && charCode !== 8) { // 8 là mã của phím backspace
                event.preventDefault();
                return;
            }

            // Cho phép các phím từ 0 đến 9, phím điều khiển (backspace), dấu chấm
            if (
                (charCode >= 48 && charCode <= 57) || // Số từ 0-9
                charCode === 46 ||                   // Dấu chấm
                charCode === 8                       // Backspace
            ) {
                const [integerPart, decimalPart] = validInput.split(".");
                // Chỉ cho phép một dấu chấm
                if (charCode === 46 && inputValue.includes(".")) {
                    event.preventDefault();
                    return;
                }
                if (decimalPart && decimalPart.length > 3) {
                    if ((integerPart && integerPart.length > 10 || charCode !== 46) || decimalPart.length > 3) {
                        event.preventDefault();
                        return;
                    }
                }
                if ((integerPart && integerPart.length > 10) || charCode !== 46) {
                    if ((decimalPart && decimalPart.length > 3) || integerPart.length > 10) {
                        event.preventDefault();
                        return;
                    }
                }
            } else {
                // Chặn các ký tự không phải số, dấu chấm
                event.preventDefault();
            }
        };

        const formatValue = (value) => {
            // Tách phần nguyên và phần thập phân
            const [integerPart, decimalPart] = value.split('.');

            // Định dạng phần nguyên với dấu phẩy
            const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Ghép lại phần nguyên đã format với phần thập phân nguyên vẹn
            if (decimalPart) {
                return `${formattedIntegerPart}.${decimalPart}`;
            }

            return formattedIntegerPart;
        };

        return (
            <tr>
                <td>{date}</td>
                <td>{location}</td>
                <td>{workItem}</td>
                <td>
                    <InputNumber 
                        value={volumeData[index]?.accomplishment}
                        onChange={handleChangeAmount}
                        min={0}
                        max={9999999999.999}
                        placeholder="Input Volume"
                        style={{ width: "100%" }}
                        formatter={formatValue}
                        onKeyPress={handleKeyPress}
                        onCopy={(e) => e.preventDefault()}   // Chặn sao chép
                        onPaste={(e) => e.preventDefault()}  // Chặn dán
                        onCut={(e) => e.preventDefault()}
                    />
                </td>
                <td>{unit}</td>
            </tr>
        )
    }