import client from "../api/axiosClient";

const apiLogin = (data) => {
    return client.post("/api/auth/login", { userId: data.userId, password: data.password });
};

const apiChangePassword = (data) => {
    return client.put("/api/user/change-password", { currentPassword: data.currentPassword, newPassword: data.newPassword });
};
export {
    apiLogin,
    apiChangePassword
};

