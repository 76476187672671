import React, { useEffect, useState } from 'react';
import { Popconfirm, Table, Tooltip } from 'antd';
import { ReactComponent as IconDelete } from "../../../assets/icons/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../../assets/icons/icon-edit.svg";
import dayjs from 'dayjs';
import { ToastFailed, ToastSuccess } from '../../common/Toast';
import { apiDeleteReport } from '../../../services/Report';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../Routes';
import { truncateString } from '../../../helps/truncateString';

const TableDailyReport = ({ pageReports, getListReport }) => {
    const [dataSource, setDataSource] = useState([]);

    const handleDelete = async (id) => {
        try {
            const response = await apiDeleteReport(id)
            if (response?.header?.resultCode === "1000") {                
                getListReport();
                ToastSuccess("Report deleted sucessfully.");
            }
        } catch (e) {
            console.log(e);
            ToastSuccess("Report deleted failed.");
        }
    }

    const navigate = useNavigate()
    const handleEdit = async (id) => {
        navigate(Routes.NewReport.path, { state: { id: id } });
    }

    useEffect(() => {
        if(pageReports.length > 0){
            setDataSource(pageReports.map(item => ({
                key: item.reportId,
                date: item.reportDate,
                user: item.userName,
                location: item.location,
                work_item: item.workName,
                manpowers: item.manpowers,
                equipments: item.equipments
            })));
        }
        else{
            setDataSource([]);
        }
    }, [pageReports]); 

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            render: (date) => dayjs(date).format('YYYY/MM/DD'),  // Định dạng ngày ở đây
        },
        {
            title: 'User',
            dataIndex: 'user',
        },
        {
            title: 'Location',
            dataIndex: 'location',
        },
        {
            title: 'Work item',
            dataIndex: 'work_item',
        },
        {
            title: 'Manpower',
            dataIndex: 'manpowers',
            render: (text) => {
                return text && text.length > 50 ? (
                    <Tooltip placement="topRight" title={text}>
                        <div className="item-show_data">
                            {truncateString(text, 50)}
                        </div>
                    </Tooltip>
                ) : (
                    <div className="item-show_data">
                        {text ? truncateString(text, 50) : ""}
                    </div>
                );
            }
        },
        {
            title: 'Equipment',
            dataIndex: 'equipments',
            render: (text) => {
                return text && text.length > 50 ? (
                    <Tooltip placement="topRight" title={text}>
                        <div className="item-show_data">
                            {truncateString(text, 50)}
                        </div>
                    </Tooltip>
                ) : (
                    <div className="item-show_data">
                        {text ? truncateString(text, 50) : ""}
                    </div>
                );
            }
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div className='d-flex px-2 gap-4'>
                    <div onClick={() => handleEdit(record.key)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEdit /></div>
                    <Popconfirm
                        title="Are you sure delete this Report?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <div style={{ cursor: "pointer" }}><IconDelete /></div>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (

        <Table
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
        />
    );
};
export default TableDailyReport;