import { Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { apiCreateUser } from "../../services/User";
import { Role } from "../../utils/Role";
import { useLocation } from "react-router-dom";
import { Routes } from "../../Routes";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { onBlurInputFormData } from "../../helps/InputData";
import IconEyeShow from "../../assets/icons/icon-eye-show.png";
import IconEyeHidden from "../../assets/icons/icon-eye-hidden.png";

export default ({ setPageIndex, isModalOpen, setIsModalOpen, handleGetListUsers, optionsSite }) => {
    const [loading, setLoading] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false);

    const [formData, setFormData] = useState({
        userId: "",
        fullname: "",
        password: "",
        email: "",
        role: 2,
        status: 1,
        siteIds: []
    });

    const [errors, setErrors] = useState({
        userId: false,
        fullname: false,
        password: false,
        siteIds: false
    });
    const [errorUserId, setErrorUserId] = useState("")
    const [errorPassword, setErrorPassword] = useState("")
    const [optionsRole, setOptionRole] = useState([]);

    const location = useLocation();

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormData({
            userId: "",
            fullname: "",
            password: "",
            email: "",
            role: 2,
            status: 1,
            siteIds: []
        });
        setErrors({
            userId: false,
            fullname: false,
            password: false,
            siteIds: false
        });
        setErrorUserId("")
        setErrorPassword("")
        setIsShowPassword(false)
    };

    const handleShowPassword = () => {
        setIsShowPassword(!isShowPassword)
    }


    useEffect(() => {
        if (isModalOpen) {
            const currentRoute = location.pathname;
            const newFormData = {
                userId: "",
                fullname: "",
                password: "",
                email: "",
                role: 2,
                status: 1,
                siteIds: []
            }
            if (currentRoute === Routes.ListAdministrators.path) {
                const newOptionRole = [
                    { label: "Sub-Admin", value: Role.Manage },
                    { label: "Admin", value: Role.Admin }
                ]
                setOptionRole(newOptionRole);
                newFormData.role = newOptionRole[0].value
            }
            if (currentRoute === Routes.ListUsers.path) {
                const newOptionRole = [{ label: "User", value: Role.User }]
                setOptionRole(newOptionRole);
                newFormData.role = newOptionRole[0].value
            }
            setFormData(newFormData)
        }
    }, [isModalOpen])

    const handleChangeInput = (e, name) => {
        if (name === "userId") {
            const trimmedValue = e.target.value.replace(/\s+/g, "");
            setFormData({ ...formData, userId: trimmedValue });
            setErrorUserId("")
        } else {
            setFormData({ ...formData, [name]: e.target.value });
        }
        setErrors({ ...errors, [name]: false });
        if (name === "password") {
            setErrorPassword("")
        }
    };

    const handleChangeSite = (value) => {
        setFormData({ ...formData, siteIds: [value] });
        setErrors({ ...errors, siteIds: false });
    };

    const onChangeSelectRole = (value) => {
        setFormData({ ...formData, role: value });
    };

    const onChangeSelectStatus = (value) => {
        setFormData({ ...formData, status: value });
    };

    useEffect(() => {
        if (formData.role === Role.Admin) {
            setErrors({ ...errors, "siteIds": false });
        }
    }, [formData.role])

    const handleCreateNewUser = async () => {

        const newErrors = {
            userId: !formData.userId.trim(),
            fullname: !formData.fullname.trim(),
            password: !formData.password,
            siteIds: formData.role === Role.Admin ? false : formData.siteIds.length === 0
        };

        setErrors(newErrors);
        if (formData.password.length < 6 && formData.password !== "") {
            setErrorPassword("Password must be at least 6 characters.")
            return;
        }
        // Kiểm tra nếu có lỗi thì dừng không gọi API
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        try {
            setLoading(true)
            const response = await apiCreateUser({ ...formData, userId: formData.userId.trim(), fullname: formData.fullname.trim(), email: formData.email.trim() });
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListUsers();
                handleCancel();
                ToastSuccess("Account created successfully")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes("UserId is existed.")) {
                    ToastFailed("This UserID already exists. Please use another ID.");
                    setErrorUserId("This UserID already exists. Please use another ID.")
                } else {
                    ToastFailed("Account created failed.")
                }
            } else {
                // Handle other unexpected errors
                console.log(error);
                ToastFailed("Account created failed!");
            }
        } finally {
            setLoading(false)
        }
    };

    return (
        <Modal title={"Add User"} open={isModalOpen} footer={null} className="modal-add-site">
            <div className="site-add-data">
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>User ID <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.userId}
                            placeholder="Input user ID"
                            className={`w-100 ${errors.userId || errorUserId ? 'input-error' : ''}`}
                            onChange={(e) => handleChangeInput(e, "userId")}
                            maxLength={30}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "userId" })}
                        />
                    </div>
                    {errors.userId && <div className="text-danger text-error">User ID is required field.</div>}
                    {errorUserId && <div className="text-danger text-error">{errorUserId}</div>}
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Password <span className="text-danger ms-1">*</span></label>
                        <div className="input-password">
                            <Input
                                value={formData.password}
                                type={isShowPassword ? "text" : "password"}
                                placeholder="Input password"
                                className={`w-100 ${errors.password || errorPassword ? 'input-error' : ''}`}
                                onChange={(e) => handleChangeInput(e, "password")}
                                maxLength={100}
                            />
                            <img
                                onClick={() => { handleShowPassword("newPassword") }}
                                src={isShowPassword ? IconEyeShow : IconEyeHidden}
                                alt="icon"
                                width={20}
                                height={20} />
                        </div>
                    </div>
                    {errors.password && <div className="text-danger text-error">Password is required field.</div>}
                    {errorPassword && <div className="text-danger text-error">{errorPassword}</div>}
                </div>
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <label>Name <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.fullname}
                            placeholder="Input full name"
                            className={`w-100 ${errors.fullname ? 'input-error' : ''}`}
                            onChange={(e) => handleChangeInput(e, "fullname")}
                            maxLength={50}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "fullname" })}
                        />
                    </div>
                    {errors.fullname && <div className="text-danger text-error">Name is required field.</div>}
                </div>
                <div className="d-flex gap-2">
                    <label>Email</label>
                    <Input
                        placeholder="Input Email"
                        className="w-100"
                        value={formData.email}
                        onChange={(e) => handleChangeInput(e, "email")}
                        onBlur={() => onBlurInputFormData({ formData, setFormData, field: "email" })}
                        maxLength={50}
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Role</label>
                    <Select
                        style={{ width: "135px" }}
                        showSearch
                        value={formData.role}
                        optionFilterProp="label"
                        onChange={onChangeSelectRole}
                        options={optionsRole}
                    />
                </div>
                <div className="d-flex gap-2">
                    <label>Status</label>
                    <Select
                        style={{ width: "135px" }}
                        showSearch
                        value={formData.status}
                        optionFilterProp="label"
                        onChange={onChangeSelectStatus}
                        options={[
                            { value: 1, label: 'Enable' },
                            { value: 0, label: 'Disable' }
                        ]}
                    />
                </div>
                <div className="d-flex flex-column gap-2 mt-4">
                    <div className="d-flex gap-2">
                        <label>Site <span className="text-danger ms-1">*</span></label>
                        <Select
                            className={`w-100 ${errors.siteIds ? 'input-error' : ''}`}
                            showSearch
                            placeholder="Select site"
                            onChange={handleChangeSite}
                            options={optionsSite}
                            value={formData.role === Role.Admin ? "All sites" : formData.siteIds}
                            disabled={formData.role === Role.Admin}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </div>
                    {errors.siteIds && <div className="text-danger text-error">Site is required field.</div>}
                </div>
            </div>
            <div className="modal-add-site_footer">
                <Button title={"Save"} width={"62px"} handleClick={handleCreateNewUser} loading={loading} />
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
