import { Input, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import TextArea from "antd/es/input/TextArea";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { apiEditEquipment } from "../../services/Equipment";
import { onBlurInputFormData } from "../../helps/InputData";

export default ({ setPageIndex, isModalOpen, setIsModalOpen, dataDetail, handleGetListEquipments }) => {
    const [formData, setFormData] = useState({ ...dataDetail })
    const [errors, setErrors] = useState({
        name: "",
        power: "",
        errRes: ""
    });

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormData({})
        setErrors({
            name: "",
            power: "",
            errRes: ""
        })
    };

    useEffect(() => {
        if (isModalOpen) {
            setFormData({ ...dataDetail })
        }
    }, [isModalOpen, dataDetail])

    const handleChangeInput = (e, name) => {
        const newFormData = { ...formData };
        newFormData[name] = e.target.value
        setFormData(newFormData)
        setErrors({ ...errors, [name]: "" , errRes: ""})
    }

    const handleEditEquipment = async () => {
        const newErrors = {
            name: formData.name.trim() ? "" : "Name is required field.",
            power: formData.power.trim() ? "" : "Power is required field.",
        };
        setErrors(newErrors);
        if (!formData.name || !formData.power) {
            return
        }

        try {
            const response = await apiEditEquipment({ ...formData, name: formData.name.trim(), power: formData.power.trim(), remark: formData.remark.trim() })
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListEquipments()
                handleCancel()
                ToastSuccess("The update has been successful")
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes("is exist.")) {
                    setErrors({ ...errors, errRes: "This equipment already exists in the system." })
                    ToastFailed("This equipment already exists in the system.");
                } else {
                    ToastFailed("The equipment update has failed.")
                }
            } else {
                // Handle other unexpected errors
                ToastFailed("The equipment update has failed.")
            }
        }
    }

    return (
        <Modal title={"Edit equipment"} open={isModalOpen} footer={null} className='modal-add-new'>
            <div className="add-new-data">
                <div>
                    <div className="d-flex gap-2">
                        <label>Name <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.name}
                            placeholder="Input name"
                            className={`${errors.name || errors.errRes ? 'input-error' : ''} w-100`}
                            onChange={(e) => handleChangeInput(e, "name")}
                            maxLength={50}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "name" })}
                        />
                    </div>
                    {errors.name && <div className="text-danger text-error">{errors.name}</div>}
                </div>
                <div>
                    <div className="d-flex gap-2">
                        <label>Capacity <span className="text-danger ms-1">*</span></label>
                        <Input
                            value={formData.power}
                            placeholder="Input power"
                            className={`${errors.power || errors.errRes ? 'input-error' : ''} w-100`}
                            onChange={(e) => handleChangeInput(e, "power")}
                            onBlur={() => onBlurInputFormData({ formData, setFormData, field: "power" })}
                            maxLength={30} />
                    </div>
                    {errors.power && <div className="text-danger text-error">{errors.power}</div>}
                    {errors.errRes && <div className="text-danger text-error">{errors.errRes}</div>}
                </div>
                <div className="d-flex gap-2">
                    <label className="remark">Remark</label>
                    <TextArea rows={3}
                        placeholder="Input remark"
                        value={formData.remark}
                        onChange={(e) => handleChangeInput(e, "remark")}
                        onBlur={() => onBlurInputFormData({ formData, setFormData, field: "remark" })}
                        maxLength={500} />
                </div>
            </div>
            <div className="modal-add-new_footer">
                <Popconfirm
                    title="This change will be reflected in all the data. Are you sure you want to commit it?"
                    okText="Confirm"
                    cancelText="Cancel"
                    onConfirm={handleEditEquipment}
                >
                    <button className="button-save">Save</button>
                </Popconfirm>
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} handleClick={handleCancel} width={"75px"} />
            </div>
        </Modal>
    );
};
