import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import './style.scss'
import Button from "../../component/common/Button";
import { Select, Input } from "antd";
import { DatePicker, Space } from 'antd';
import { TableManpower } from "../../component/report/new_report/Manpower";
import { PlusOutlined } from "@ant-design/icons";
import { TableEquipment } from "../../component/report/new_report/Equipment";
import { useLocation, useNavigate } from "react-router-dom";
import { apiDetailSiteUser } from "../../services/Site";
import { apiCreateReport, apiDetailReport, apiEditReport, getReportByModel } from "../../services/Report";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { ToastFailed, ToastSuccess } from "../../component/common/Toast";
import { ToastContainer } from "react-toastify";
import { Routes } from "../../Routes";
import CancelPopup from "../../component/common/CancelPopup";
import { getSiteId, getUser } from "../../utils/auth";
import { useSelector } from "react-redux";
import { apiCreateOneWorkPlan } from "../../services/WorkPlan";
import { useEditing } from "../../hook/EdittingContext";

dayjs.extend(utc);

export default () => {
    const [items, setItem] = useState([
        { title: 'Daily Report' },
        { title: 'New Report' }
    ]);
    const [loading, setLoading] = useState(false)
    const { isEditing, setIsEditing } = useEditing();
    const location = useLocation();
    const { id } = location.state || {};
    const user = JSON.parse(getUser());
    let siteId = useSelector((state) => state.site).selectedSite?.siteId || JSON.parse(getSiteId())?.siteId;
    const [formData, setFormData] = useState({
        SiteId: siteId,
        WorkItemId: null,
        Location: null,
        LocationSplit: null,
        ReportDate: null,
        Manpowers: null,
        Equipments: null
    })
    const [listLocationX, setlistLocationX] = useState([]);
    const [valueLocationX, setValueLocationX] = useState(null);
    const [listLocationY, setListLocationY] = useState([]);
    const [valueLocationY, setValueLocationY] = useState(null);
    const [listLocationZ, setListLocationZ] = useState([]);
    const [valueLocationZ, setValueLocationZ] = useState(null);
    const [listWorkItem, setWorkItem] = useState([]);
    const tableRefEquiment = useRef();
    const tableRefManpower = useRef();
    const [messagePopup, setMessagePopup] = useState('');

    const getSite = async (siteId) => {
        try {
            const responseDetailSite = await apiDetailSiteUser(siteId)
            if (responseDetailSite?.header?.resultCode === "1000") {
                setlistLocationX(responseDetailSite?.records?.site?.locationX?.map(item => ({
                    value: item,
                    label: item,
                })));
                setListLocationY(responseDetailSite?.records?.site?.locationY?.map(item => ({
                    value: item,
                    label: item,
                })));
                setListLocationZ(responseDetailSite?.records?.site?.locationZ?.map(item => ({
                    value: item,
                    label: item,
                })));
                setLstEquimentCbb(responseDetailSite?.records?.site?.equipments?.map(item => ({
                    value: item.equipmentId,
                    label: item.name + "_" + item.power,
                })));
                setLstManpowerCbb(responseDetailSite?.records?.site?.manpowers?.map(item => ({
                    value: item.manpowerId,
                    label: item.name,
                })));
                setWorkItem(responseDetailSite?.records?.site?.workItems?.map(item => ({
                    value: item.workItemId,
                    label: item.name,
                })));
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Hàm xử lý sự kiện thay đổi date
    const onChangeDate = (date) => {
        setIsEditing(true)
        setFormData({
            ...formData,
            ReportDate: date ? date : dayjs() // Nếu date là null
        });
    };

    // Hàm xử lý sự kiện cho từng Select Location
    const handleChangeLocaX = (value) => {
        setIsEditing(true)
        setValueLocationX(value)
    };
    const handleChangeLocaY = (value) => {
        setIsEditing(true)
        setValueLocationY(value);
    }
    const handleChangeLocaZ = (value) => {
        setIsEditing(true)
        setValueLocationZ(value);
    }

    // Hàm xử lý sự kiện thay đổi workItem
    const handleChangeWrokItem = (value) => {
        setIsEditing(true)
        setFormData({
            ...formData,
            WorkItemId: value
        });
    };

    const [pageManpowers, setPageManpowers] = useState([{ manpower: null, amount: 0 }]);
    const [lstManpowerCbb, setLstManpowerCbb] = useState([]);
    const [pageEquipments, setPageEquipments] = useState([{ equipment: null, amount: 0 }]);
    const [lstEquimentCbb, setLstEquimentCbb] = useState([]);

    const getDataRepotByModel = async () => {
        var request = {
            SiteId: siteId,
            WorkItemId: formData.WorkItemId,
            Location: formData.Location,
            DateReport: formData.ReportDate ? dayjs(formData.ReportDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null
        }

        try {
            setPageManpowers([{ manpower: null, amount: 1 }]);
            setPageEquipments([{ equipment: null, amount: 1 }]);
            const responseData = await getReportByModel(request)
            if (responseData?.header?.resultCode === "1000") {
                if (responseData?.records?.report) {
                    if (responseData?.records?.report?.listManpower?.length > 0) {
                        setPageManpowers(responseData?.records?.report?.listManpower.map(item => ({
                            manpower: item.id,
                            amount: 1,
                        })));
                    }
                    if (responseData?.records?.report?.listEquipment?.length > 0) {
                        setPageEquipments(responseData?.records?.report?.listEquipment.map(item => ({
                            equipment: item.id,
                            amount: 1,
                        })));
                    }
                    setFormData({
                        ...formData,
                        WorkItemId: responseData?.records?.report?.workItemId,
                        Location: responseData?.records?.report?.location,
                        LocationSplit: responseData?.records?.report?.locationSplit,
                    });
                    setValueLocationX(null);
                    setValueLocationY(null);
                    setValueLocationZ(null);
                    if (responseData?.records?.report?.locationSplit) {
                        let stringLocation = responseData?.records?.report?.locationSplit?.split('/');
                        if (stringLocation) {
                            setValueLocationX(stringLocation[0]);
                            setValueLocationY(stringLocation[1]);
                            setValueLocationZ(stringLocation[2]);
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getDataReportDetail = async (id) => {
        try {
            const responseData = await apiDetailReport(id)
            if (responseData?.header?.resultCode === "1000") {
                if (responseData?.records?.report?.listManpower?.length > 0) {
                    setPageManpowers(responseData?.records?.report?.listManpower.map(item => ({
                        manpower: item.id,
                        amount: item.amount,
                    })));
                }
                if (responseData?.records?.report?.listEquipment?.length > 0) {
                    setPageEquipments(responseData?.records?.report?.listEquipment.map(item => ({
                        equipment: item.id,
                        amount: item.amount,
                    })));
                }
                setFormData({
                    ...formData,
                    WorkItemId: responseData?.records?.report?.workItemId,
                    Location: responseData?.records?.report?.location,
                    ReportDate: dayjs(responseData?.records?.report?.reportDate),
                    LocationSplit: responseData?.records?.report?.locationSplit,
                    SiteId: responseData?.records?.report?.siteId,
                });
                if (responseData?.records?.report?.locationSplit) {
                    let stringLocation = responseData?.records?.report?.locationSplit?.split('/');
                    if (stringLocation) {
                        setValueLocationX(stringLocation[0]);
                        setValueLocationY(stringLocation[1]);
                        setValueLocationZ(stringLocation[2]);
                    }
                }
            }
            else {
                ToastFailed(responseData?.header?.message);
                navigate(Routes.ListReports.path)
            }
        } catch (e) {
            ToastFailed(e?.response?.data?.header?.message);
            navigate(Routes.ListReports.path)
        }
    }

    const handleAddManpower = () => {
        setIsEditing(true)
        const newPageManpowers = [...pageManpowers]
        newPageManpowers.push({
            manpower: null,
            amount: 1
        })
        setPageManpowers(newPageManpowers);
        setIsSubmitting(false);
    }

    const handleAddEquipment = () => {
        setIsEditing(true)
        const newPageEquipments = [...pageEquipments]
        newPageEquipments.push({
            equipment: null,
            amount: 1
        })
        setPageEquipments(newPageEquipments);
        setIsSubmitting(false);
    }

    const [errors, setErrors] = useState({ date: null, location: null, workItem: null });
    const validateForm = () => {
        let hasErrors = false;
        let newErrors = {};
        if (!formData.ReportDate) {
            newErrors.date = "Date is required field";
            hasErrors = true;
        }
        if (!valueLocationX || !valueLocationY || !valueLocationZ) {
            newErrors.location = "Location is required field";
            hasErrors = true;
        }
        if (!formData.WorkItemId) {
            newErrors.workItem = "Work item is required field";
            hasErrors = true;
        }
        if (pageManpowers.length == 0) {
            ToastFailed("Manpower is required field");
            hasErrors = true;
        }
        if (pageEquipments.length == 0) {
            ToastFailed("Equipment is required field");
            hasErrors = true;
        }
        setErrors(newErrors);
        return !hasErrors; // Trả về true nếu không có lỗi
    };

    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSave = () => {
        setIsSubmitting(true);
        const isValidEquiment = tableRefEquiment.current.validateEquipment();
        const isValidManpower = tableRefManpower.current.validateManpower();
        if (validateForm() && isValidEquiment && isValidManpower) {
            setLoading(true);
            const request = {
                ReportId: id,
                SiteId: siteId,
                WorkItemId: formData.WorkItemId,
                Location: valueLocationX + valueLocationY + valueLocationZ,
                LocationSplit: valueLocationX + "/" + valueLocationY + "/" + valueLocationZ,
                ReportDate: dayjs(formData.ReportDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
                Manpowers: formatpageManpowerToString(pageManpowers.filter(x => x.manpower != null)),
                Equipments: formatpageEquipmentToString(pageEquipments.filter(x => x.equipment != null)),
                ListManpower: pageManpowers.filter(x => x.manpower != null).map(item => item.manpower),
                ListEquipment: pageEquipments.filter(x => x.equipment != null).map(item => item.equipment),
            }
            if (id) {
                onUpdate(request);
            }
            else {
                onSave(request);
            }
        }
    }
    const onSave = async (request) => {
        try {
            const response = await apiCreateReport(request)
            if (response?.header?.resultCode === "1000") {
                const requestWorkPlan = {
                    SiteId: siteId,
                    Location: request.LocationSplit,
                    WorkItemId: request.WorkItemId,
                    DesignVolume: 0,
                    ProductionRate: 0,
                    OperatingRatio: 0,
                    Duration: 0,
                    ProductionRateAdjust: 0
                }
                const responseWorkPlan = await apiCreateOneWorkPlan(requestWorkPlan)
                navigate(Routes.ListReports.path)
                ToastSuccess("Your report has been successfully submitted!.");
            }
            else {
                ToastFailed("Unfortunately, your report submission was unsuccessful. Please check your information and try again!");
            }
        } catch (e) {
            if (e.response?.data?.header?.resultCode == "9003") {
                ToastFailed(e?.response?.data?.header?.message);
            }
            else {
                ToastFailed("Unfortunately, your report submission was unsuccessful. Please check your information and try again!");
            }
        } finally {
            setLoading(false)
            setIsEditing(false)
        }
    }
    const onUpdate = async (request) => {
        try {
            const response = await apiEditReport(request)
            if (response?.header?.resultCode === "1000") {
                const requestWorkPlan = {
                    SiteId: siteId,
                    Location: request.LocationSplit,
                    WorkItemId: request.WorkItemId,
                    DesignVolume: 0,
                    ProductionRate: 0,
                    OperatingRatio: 0,
                    Duration: 0,
                    ProductionRateAdjust: 0
                }
                const responseWorkPlan = await apiCreateOneWorkPlan(requestWorkPlan)
                navigate(Routes.ListReports.path)
                ToastSuccess("Report updated successfully!.");
            }
            else {
                ToastFailed("Report update failed!");
            }
        } catch (e) {
            if (e.response?.data?.header?.resultCode == "9003") {
                ToastFailed(e?.response?.data?.header?.message);
            }
            else {
                ToastFailed("Report update failed!");
            }
        } finally {
            setLoading(false)
            setIsEditing(false)
        }
    }

    const cancelPopupRef = useRef(null);
    const handleCancel = () => {
        if (cancelPopupRef.current) {
            setIsEditing(false)
            cancelPopupRef.current.showModal();
        }
    }

    const handleConfirmPop = () => {
        navigate(Routes.ListReports.path)
    }

    const loadData = () => {
        // Trường hợp edit report có id
        if (id) {
            setItem([
                { title: 'Daily Report' },
                { title: 'Edit Report' }
            ]);
            setMessagePopup('Your unsaved data will be deleted to update records from the system?');
            getDataReportDetail(id);
        }
        //Nếu là thêm mới add dữ liệu của ngày trước đó đã thêm
        else {
            setMessagePopup('Your changes have not been saved. Are you sure you want to leave this page?');
            getDataRepotByModel();
        }
    }

    const formatpageManpowerToString = (data) => {
        return data.map(item => `${item.manpower}_${item.amount}`).join('/');
    };

    const formatpageEquipmentToString = (data) => {
        return data.map(item => `${item.equipment}_${item.amount}`).join('/');
    };

    useEffect(() => {
        getSite(siteId);
        loadData();
    }, [siteId]);

    const [disabledAddManpower, setDisabledAddManpower] = useState(false);
    const [disabledAddEquipment, setDisabledAddEquipment] = useState(false);
    useEffect(() => {
        setDisabledAddManpower(false);
        setDisabledAddEquipment(false);
        if (pageManpowers.length === lstManpowerCbb.length) {
            setDisabledAddManpower(true);
        }
        if (pageEquipments.length === lstEquimentCbb.length) {
            setDisabledAddEquipment(true);
        }
    }, [pageManpowers, lstManpowerCbb, pageEquipments, lstEquimentCbb]);

    useEffect(() => {
        if (valueLocationX && valueLocationY && valueLocationZ) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                Location: valueLocationX + valueLocationY + valueLocationZ,
                LocationSplit: valueLocationX + "/" + valueLocationY + "/" + valueLocationZ,
            }));
        }

        // Chỉ áp dụng cho thêm mới
        // if(formData.ReportDate && formData.Location && formData.WorkItemId && !id){
        //     getDataRepotByModel();
        // }
    }, [formData.Location, formData.WorkItemId, formData.ReportDate]);
    return (
        <>
            <BreadcrumbHeader title={items[1].title} items={items} />
            <ToastContainer />
            <div className="content">
                <div className="new-report-page p-4">
                    <div className="new-report-data">
                        <div>
                            <div className="d-flex gap-2">
                                <label>User name<span className="text-danger ms-1">*</span></label>
                                <Input value={user.fullName} disabled={true} />
                            </div>
                        </div>
                        <div>
                            <div className="d-flex gap-2">
                                <label>Date<span className="text-danger ms-1">*</span></label>
                                <Space direction="vertical">
                                    <DatePicker style={{ width: "196px" }} onChange={onChangeDate} value={formData.ReportDate} format="YYYY-MM-DD" />
                                </Space>
                            </div>
                            <div className="d-flex gap-2">
                                <label></label>
                                {errors.date && <div className="text-danger text-error">{errors.date}</div>}
                            </div>
                        </div>
                        <div>
                            <div className="d-flex gap-2">
                                <label>Location<span className="text-danger ms-1">*</span></label>
                                <div className="d-flex gap-2">
                                    <Select
                                        style={{ width: "68px" }}
                                        showSearch
                                        options={listLocationX}
                                        placeholder="Select"
                                        value={valueLocationX}
                                        onChange={handleChangeLocaX}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                    <Select
                                        style={{ width: "68px" }}
                                        showSearch
                                        options={listLocationY}
                                        placeholder="Select"
                                        value={valueLocationY}
                                        onChange={handleChangeLocaY}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                    <Select
                                        style={{ width: "68px" }}
                                        showSearch
                                        options={listLocationZ}
                                        placeholder="Select"
                                        value={valueLocationZ}
                                        onChange={handleChangeLocaZ}
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex gap-2">
                                <label></label>
                                {errors.location && <div className="text-danger text-error">{errors.location}</div>}
                            </div>
                        </div>
                        <div>
                            <div className="d-flex gap-2">
                                <label>Work item<span className="text-danger ms-1">*</span></label>
                                <Select
                                    className="w-100"
                                    showSearch
                                    placeholder="Select work item"
                                    options={listWorkItem}
                                    onChange={handleChangeWrokItem}
                                    value={formData.WorkItemId}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </div>
                            <div className="d-flex gap-2">
                                <label></label>
                                {errors.workItem && <div className="text-danger text-error">{errors.workItem}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-4">
                        <div className="col-6 form-table-manpower">
                            <div className="title">Manpower</div>
                            <TableManpower ref={tableRefManpower}
                                pageManpowers={pageManpowers}
                                setPageManpowers={setPageManpowers}
                                lstManpowerCbb={lstManpowerCbb}
                                isSubmitting={isSubmitting}
                            />
                            <button disabled={disabledAddManpower} className="btn-add" onClick={handleAddManpower}><PlusOutlined /><span>Add row</span></button>
                        </div>
                        <div className="col-6 form-table-manpower">
                            <div className="title">Equipment</div>
                            <TableEquipment ref={tableRefEquiment}
                                pageEquipments={pageEquipments}
                                setPageEquipments={setPageEquipments}
                                lstEquimentCbb={lstEquimentCbb}
                                isSubmitting={isSubmitting}
                            />
                            <button disabled={disabledAddEquipment} className="btn-add" onClick={handleAddEquipment}><PlusOutlined /><span>Add row</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="report-page-bottom">
                <Button title={"Cancel"} BgColor={"#fff"} fontColor={"#000000D9"} borderColor={"#D9D9D9"} width={"75px"} handleClick={handleCancel} />
                <Button title={id ? "Update" : "Save"} width={"62px"} handleClick={handleSave} loading={loading} />
            </div>
            <CancelPopup
                ref={cancelPopupRef}
                onConfirm={handleConfirmPop}
                onCancel={() => console.log("Popup closed")}
                message={messagePopup}
            />
        </>
    );
};
