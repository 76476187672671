import React, { useEffect, useState } from "react";
import { Modal, Upload, Button, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { apiImportSite } from "../../services/Site";
import { ToastFailed, ToastSuccess } from "../common/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setImportStatus, setProgressImportSite } from "../../store/actions/siteAction";

const ModalUploadSite = ({ visible, setVisible, handleGetListSites, setPageIndex }) => {
  const [fileList, setFileList] = useState([]);
  const [err, setErr] = useState("")
  const dispatch = useDispatch()
  const progress = useSelector((state) => state.site)?.progress
  const uploadStatus = useSelector((state) => state.site)?.status

  const isValidFile = (file) => {
    const allowedExtensions = /\.(xls|xlsx)$/i;
    return allowedExtensions.test(file.name);
  };
  
  const handleFileChange = async ({ file, fileList }) => {
    if (file.status === "removed") {
      setFileList(fileList);
      dispatch(setProgressImportSite(0))
      dispatch(setImportStatus("idle"))
      return;
    }

    if (isValidFile(file)) {
      setFileList([file]);
      dispatch(setProgressImportSite(0))
      dispatch(setImportStatus("uploading"))
      await handleImportSite(file);
    } else {
      dispatch(setProgressImportSite(0))
      dispatch(setImportStatus("error"))
      setErr("The import file is incorrect. Please check again.")
      ToastFailed("The import file is incorrect. Please check again.")
    }
  };

  useEffect(()=> {
    if(visible){
      setErr("")
      if(progress === 0 || progress === 100){
        dispatch(setImportStatus("idle"))
      }
    }
  }, [visible])

  const handleImportSite = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Fake API progress
      for (let i = 0; i <= 90; i += 10) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        // setProgress(i);
        dispatch(setProgressImportSite(i))
      }

      const response = await apiImportSite(formData);
      if (response?.message === "Import successful.") {
        dispatch(setProgressImportSite(100))
        dispatch(setImportStatus("success"))
        setPageIndex(1)
        handleGetListSites()
        setTimeout(()=> {
          handleCancel()
        }, 1000)
        ToastSuccess("Import site successfully.")
      } else{
        throw new Error("Upload failed!");
      }
    } catch (error) {      
      const errorRes = error?.response?.data?.error;
        if(errorRes === "Site has been already existed."){
          ToastFailed("The Site Id already exists. Please check again.")
          setErr("The Site Id already exists. Please check again.")
        }else if(errorRes === "There is no site to import." || 
          errorRes?.includes("Invalid Excel file.") || 
          errorRes?.includes("SiteId must be match.")||
          errorRes?.includes("Invalid location workplan or report.") ||
          errorRes?.includes("The location does not exist in the list.")||
          errorRes?.includes("Location and location split of report must be match.")||
          errorRes?.includes("Location error") ||
          errorRes){
            setErr("The import file is incorrect. Please check again.")
            ToastFailed("The import file is incorrect. Please check again.")
        }else{
            ToastFailed("Some thing went wrong, please try again.")
            setErr("Some thing went wrong, please try again.")
        }
        dispatch(setProgressImportSite(0))
        dispatch(setImportStatus("error"))
    }
  };

  const handleCancel = () => {
    setVisible(false);
    if(progress === 0 || progress === 100){
      dispatch(setImportStatus("idle"))
    }
    setFileList([]);
  };

  return (
    <Modal
      title="Import"
      open={visible}
      footer={null}
      className="modal-import-site"
      onCancel={handleCancel}
    >
      <div className="modal-import-site_content">
        <div className="d-flex flex-column gap-2">
          <div>Select file and upload will start automatically</div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Upload
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              onRemove={() => {
                setFileList([]);
                dispatch(setProgressImportSite(0))
                dispatch(setImportStatus("idle"))
              }}
            >
              <Button icon={<UploadOutlined />} style={{ borderRadius: "2px" }}>
                Select file
              </Button>
            </Upload>
            <div style={{ color: fileList.length === 0 ? "#aaa" : "#000" }}>
              {fileList.length === 0 && "No file chosen"}
            </div>
          </div>
        </div>

        {uploadStatus === "uploading" && (
          <div>
            <div>Uploading...</div>
            <Progress percent={progress} status="active" />
          </div>
        )}

        {uploadStatus === "success" && (
          <div>
            <div>Uploaded</div>
            <Progress percent={100} status="success" />
          </div>
        )}

        {uploadStatus === "error" && (
          <div>
            <div style={{ color: "red" }}>{err}</div>
            <Progress percent={0} status="exception" />
          </div>
        )}

      </div>

      <div className="modal-import-site_footer">
        <Button
          style={{ marginTop: 20, borderRadius: "2px" }}
          onClick={handleCancel}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ModalUploadSite;
