import React, { createContext, useContext, useEffect, useState } from 'react';
import { Popconfirm, Table, Tooltip } from 'antd';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import { ReactComponent as IconDelete } from "../../assets/icons/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/icons/icon-edit.svg";
// import { ReactComponent as IconEye } from "../../assets/icons/icon-eye.svg";
import { apiDeleteEquipment, apiDetailEquipment } from '../../services/Equipment';
import { FormatTime } from '../../utils/FormatTime';
import ModalEditEquipment from './ModalEditEquipment';
import { ToastFailed } from '../common/Toast';
import { truncateString } from '../../helps/truncateString';
// import ModalDetailEquipment from './ModalDetailEquipment';

const DragIndexContext = createContext({
    active: -1,
    over: -1,
});
const dragActiveStyle = (dragState, id) => {
    const { active, over, direction } = dragState;
    // drag active style
    let style = {};
    if (active && active === id) {
        style = {
            backgroundColor: 'gray',
            opacity: 0.5,
        };
    }
    // dragover dashed style
    else if (over && id === over && active !== over) {
        style =
            direction === 'right'
                ? {
                    borderRight: '1px dashed gray',
                }
                : {
                    borderLeft: '1px dashed gray',
                };
    }
    return style;
};
const TableBodyCell = (props) => {
    const dragState = useContext(DragIndexContext);
    return (
        <td
            {...props}
            style={{
                ...props.style,
                ...dragActiveStyle(dragState, props.id),
            }}
        />
    );
};
const TableHeaderCell = (props) => {
    const dragState = useContext(DragIndexContext);
    const { attributes, listeners, setNodeRef, isDragging } = useSortable({
        id: props.id,
    });
    const style = {
        ...props.style,
        cursor: 'move',
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
                userSelect: 'none',
            }
            : {}),
        ...dragActiveStyle(dragState, props.id),
    };
    return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const TableEquipment = ({ setPageIndex, listData, handleGetListEquipments }) => {
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    // const [isModalOpenView, setIsModalOpenView] = useState(false);

    const [dataDetail, setDataDetail] = useState({})

    const handleDeleteEquipment = async (id) => {
        try {
            const response = await apiDeleteEquipment(id)
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListEquipments()
            }
        } catch (error) {
            if (error.response) {
                // Access the response object in the error
                if (error.response.data.header?.message?.includes("is using another report.")) {
                    ToastFailed("This items is linked to a report. You cannot delete it.");
                } else if (error.response.data.header?.message?.includes("is used in site")) {
                    ToastFailed("This items is linked to a site. You cannot delete it.");
                } else {
                    ToastFailed("Something went wrong, please try again!");
                }
            } else {
                // Handle other unexpected errors
                ToastFailed("Something went wrong, please try again!");
            }
        }
    }

    const handleGetDetailEquipment = async (id) => {
        try {
            const response = await apiDetailEquipment(id)
            if (response?.header?.resultCode === "1000") {
                setDataDetail(response?.records?.equipment)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const showModalEdit = (id) => {
        setIsModalOpenEdit(true);
        handleGetDetailEquipment(id)
    };

    // const showModalView = (id) => {
    //     setIsModalOpenView(true);
    //     handleGetDetailEquipment(id)
    // };

    const baseColumns = [
        {
            title: '#',
            dataIndex: 'index',
        },
        {
            title: 'Equipment',
            dataIndex: 'name',
        },
        {
            title: 'Project Name',
            dataIndex: 'siteName',
            render: (text) => (
                <div>
                    {text ? text : "All"}
                </div>
            ),
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            render: (text) => {
                return text && text.length > 100 ? (
                    <Tooltip placement="topRight" title={text}>
                        <div className="item-show_data">
                            {truncateString(text, 100)}
                        </div>
                    </Tooltip>
                ) : (
                    <div className="item-show_data">
                        {text ? truncateString(text, 100) : ""}
                    </div>
                );
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            render: (text) => (
                <div>
                    {FormatTime(text)}
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div className='d-flex px-2 gap-4'>
                    {/* <div onClick={() => showModalView(record.equipmentId)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEye /></div> */}
                    <div onClick={() => showModalEdit(record.equipmentId)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEdit /></div>
                    <Popconfirm
                        title="Are you sure delete this equipment?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteEquipment(record.equipmentId)}
                    >
                        <div style={{ color: "#1890FF", cursor: "pointer" }}><IconDelete /></div>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const [dragIndex, setDragIndex] = useState({
        active: -1,
        over: -1,
    });

    const [columns, setColumns] = useState(() =>
        baseColumns.map((column) => ({
            ...column,
            key: column.dataIndex,
            onHeaderCell: () => ({
                id: column.dataIndex,
            }),
            onCell: () => ({
                id: column.dataIndex,
            }),
        })),
    );
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setColumns((prevState) => {
                const activeIndex = prevState.findIndex((i) => i.key === active?.id);
                const overIndex = prevState.findIndex((i) => i.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
        setDragIndex({
            active: -1,
            over: -1,
        });
    };
    const onDragOver = ({ active, over }) => {
        const activeIndex = columns.findIndex((i) => i.key === active.id);
        const overIndex = columns.findIndex((i) => i.key === over?.id);
        setDragIndex({
            active: active.id,
            over: over?.id,
            direction: overIndex > activeIndex ? 'right' : 'left',
        });
    };

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            collisionDetection={closestCenter}
        >
            {/* <ModalDetailEquipment
                isModalOpen={isModalOpenView}
                setIsModalOpen={setIsModalOpenView}
                dataDetail={dataDetail}
            /> */}
            <ModalEditEquipment
                setPageIndex={setPageIndex}
                isModalOpen={isModalOpenEdit}
                setIsModalOpen={setIsModalOpenEdit}
                dataDetail={dataDetail}
                handleGetListEquipments={handleGetListEquipments} />
            <SortableContext items={columns.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                <DragIndexContext.Provider value={dragIndex}>
                    <Table
                        rowKey="key"
                        columns={columns}
                        dataSource={listData}
                        components={{
                            header: {
                                cell: TableHeaderCell,
                            },
                            body: {
                                cell: TableBodyCell,
                            },
                        }}
                    />
                </DragIndexContext.Provider>
            </SortableContext>
            <DragOverlay>
                <th
                    style={{
                        backgroundColor: 'gray',
                        padding: 16,
                    }}
                >
                    {columns[columns.findIndex((i) => i.key === dragIndex.active)]?.title}
                </th>
            </DragOverlay>
        </DndContext>
    );
};
export default TableEquipment;