import { Outlet } from "react-router-dom";
import { SidebarReportManagement } from "./SidebarReportManagement";
import { HeaderUser } from "../HeaderUser";
export const LayoutReportManagement = () => {
    return (
        <div style={{ flexDirection: "column", position: "relative", minHeight: "100vh", height: "auto" }} className="d-flex">
            <HeaderUser />
            <div className="d-flex">
                <SidebarReportManagement />
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
