import { PROGRESS_IMPORT, SELECTED_SITE, STATUS_IMPORT } from "../actions/siteAction";

const initialState = {
    selectedSite: "",
    progress: 0,
    status: 'idle'
};

const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_SITE:
            return {
                ...state,
                selectedSite: action.payload,
            };
        case PROGRESS_IMPORT:
            return {
                ...state,
                progress: action.payload,
                };
        case STATUS_IMPORT:
            return {
                ...state,
                status: action.payload,
            };        
        default:
            return state;
    }
};

export default siteReducer;
