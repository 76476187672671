import React, { createContext, useContext, useState } from 'react';
import { Popconfirm, Table } from 'antd';
import ModalDetailUser from './ModalDetailUser';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import { ReactComponent as IconDelete } from "../../assets/icons/icon-delete.svg";
import { ReactComponent as IconEdit } from "../../assets/icons/icon-edit.svg";
import { ReactComponent as IconEye } from "../../assets/icons/icon-eye.svg";
import { FormatTime } from '../../utils/FormatTime';
import { apiDeleteUser, apiDetailUser } from '../../services/User';
import ModalEditUser from './ModalEditUser';
import dayjs from 'dayjs';
import { ToastFailed, ToastSuccess } from "../common/Toast";

const DragIndexContext = createContext({
    active: -1,
    over: -1,
});
const dragActiveStyle = (dragState, id) => {
    const { active, over, direction } = dragState;
    // drag active style
    let style = {};
    if (active && active === id) {
        style = {
            backgroundColor: 'gray',
            opacity: 0.5,
        };
    }
    // dragover dashed style
    else if (over && id === over && active !== over) {
        style =
            direction === 'right'
                ? {
                    borderRight: '1px dashed gray',
                }
                : {
                    borderLeft: '1px dashed gray',
                };
    }
    return style;
};
const TableBodyCell = (props) => {
    const dragState = useContext(DragIndexContext);
    return (
        <td
            {...props}
            style={{
                ...props.style,
                ...dragActiveStyle(dragState, props.id),
            }}
        />
    );
};
const TableHeaderCell = (props) => {
    const dragState = useContext(DragIndexContext);
    const { attributes, listeners, setNodeRef, isDragging } = useSortable({
        id: props.id,
    });
    const style = {
        ...props.style,
        cursor: 'move',
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
                userSelect: 'none',
            }
            : {}),
        ...dragActiveStyle(dragState, props.id),
    };
    return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const TableUsers = ({ setPageIndex, listUsers, handleGetListUsers, optionsSite, optionsSiteEnable }) => {
    const [isModalOpenView, setIsModalOpenView] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    // const isRowDisabled = (record) => record.status !== 'Active';
    const [userDetail, setUserDetail] = useState({})

    const handleDeleteUser = async (id) => {
        try {
            const response = await apiDeleteUser(id)
            if (response?.header?.resultCode === "1000") {
                setPageIndex(1)
                handleGetListUsers()
            } else {
                ToastFailed(response?.header?.resultCode);
            }
        } catch (error) {
            if (error.response) {
                ToastFailed(error.response.data.header?.message);
            } else {
                console.log(error);
            }
        }
    }

    const handleGetDetailUser = async (id) => {
        try {
            const response = await apiDetailUser(id)
            if (response?.header?.resultCode === "1000") {
                setUserDetail(response?.records?.user)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const showModalView = (id) => {
        setIsModalOpenView(true);
        handleGetDetailUser(id)
    };

    const showModalEdit = (id) => {
        setIsModalOpenEdit(true);
        handleGetDetailUser(id)
    };

    const baseColumns = [
        {
            title: '#',
            dataIndex: 'index',
        },
        {
            title: 'Name',
            dataIndex: 'fullname',
            // render: (text, record) => (
            //     <div style={{ color: isRowDisabled(record) ? 'gray' : 'black' }}>
            //         {text}
            //     </div>
            // ),
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (text) =>
                <>
                    {text === 0 && <div>Admin</div>}
                    {text === 1 && <div>Sub-Admin</div>}
                    {text === 2 && <div>User</div>}
                </>
        },
        {
            title: 'Project Name',
            dataIndex: 'siteIds',
            render: (text) =>
                <>
                    {text[0]?.siteName}
                </>
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
        },
        {
            title: 'Created date',
            dataIndex: 'createdAt',
            // render: (text) => <div>{FormatTime(text)}</div>,
            render: (date) => <div>{dayjs.utc(date).format('YYYY/MM/DD')}</div>,
        },
        {
            title: 'Update By',
            dataIndex: 'lastModifiedBy',
        },
        {
            title: 'Update date',
            dataIndex: 'updatedAt',
            // render: (text) => <div>{FormatTime(text)}</div>,
            render: (date) => <div>{dayjs.utc(date).format('YYYY/MM/DD')}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => <div className='d-flex gap-2'>
                <div className={`mt-2 ${text === 1 ? "enable" : "disable"}`}></div>
                <div>{text === 1 ? "Enable" : "Disable"}</div>
            </div>,
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div className='d-flex px-2 gap-4'>
                    <div onClick={() => showModalView(record.userId)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEye /></div>
                    <div onClick={() => showModalEdit(record.userId)} style={{ color: "#1890FF", cursor: "pointer" }}><IconEdit /></div>
                    <Popconfirm
                        title="Are you sure delete this User?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteUser(record.userId)}
                    >
                        <div style={{ color: "#1890FF", cursor: "pointer" }}><IconDelete /></div>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const [dragIndex, setDragIndex] = useState({
        active: -1,
        over: -1,
    });

    const [columns, setColumns] = useState(() =>
        baseColumns.map((column) => ({
            ...column,
            key: column.dataIndex,
            onHeaderCell: () => ({
                id: column.dataIndex,
            }),
            onCell: () => ({
                id: column.dataIndex,
            }),
        })),
    );
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setColumns((prevState) => {
                const activeIndex = prevState.findIndex((i) => i.key === active?.id);
                const overIndex = prevState.findIndex((i) => i.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
        setDragIndex({
            active: -1,
            over: -1,
        });
    };
    const onDragOver = ({ active, over }) => {
        const activeIndex = columns.findIndex((i) => i.key === active.id);
        const overIndex = columns.findIndex((i) => i.key === over?.id);
        setDragIndex({
            active: active.id,
            over: over?.id,
            direction: overIndex > activeIndex ? 'right' : 'left',
        });
    };
    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            collisionDetection={closestCenter}
        >
            <ModalDetailUser isModalOpen={isModalOpenView} setIsModalOpen={setIsModalOpenView} userDetail={userDetail} optionsSite={optionsSite} />
            <ModalEditUser setPageIndex={setPageIndex} isModalOpen={isModalOpenEdit} setIsModalOpen={setIsModalOpenEdit} userDetail={userDetail} optionsSite={optionsSiteEnable} handleGetListUsers={handleGetListUsers} />

            <SortableContext items={columns.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                <DragIndexContext.Provider value={dragIndex}>
                    <Table
                        rowKey="key"
                        columns={columns}
                        dataSource={listUsers}
                        components={{
                            header: {
                                cell: TableHeaderCell,
                            },
                            body: {
                                cell: TableBodyCell,
                            },
                        }}
                    />
                </DragIndexContext.Provider>
            </SortableContext>
            <DragOverlay>
                <th
                    style={{
                        backgroundColor: 'gray',
                        padding: 16,
                    }}
                >
                    {columns[columns.findIndex((i) => i.key === dragIndex.active)]?.title}
                </th>
            </DragOverlay>
        </DndContext>
    );
};
export default TableUsers;