import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./index.scss"
import { EditingProvider } from './hook/EdittingContext';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <React.StrictMode>
    <EditingProvider>
      <App />
    </EditingProvider>
  </React.StrictMode>,
);

reportWebVitals();