import React, { useEffect, useState } from "react";
import { Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import { TableRow } from "./TableRow";

export const TableAccomplishment = ({ pageReports, setLstData, lstData ,setPageReports }) => {
    const [volumeData, SetVolumeData] = useState([]);

    useEffect(() => {
        if (pageReports.length > 0) {
            SetVolumeData(pageReports.map(item => ({
                date: dayjs(item.dateReport).format('YYYY-MM-DD'),
                location: item.location,
                workItem: item.workItemName,
                accomplishment: item.accomplishment,
                reportId: item.reportId,
                unit: item.unit
            })));
        }
        else {
            SetVolumeData([]);
        }
    }, [pageReports]);

    const handleUpdateData = (data) => {
        if (data) {
            const findLstData = lstData.find(x => x.reportId === data.reportId);
            if (findLstData) {
                findLstData.accomplishment = data.accomplishment;
                setLstData([...lstData]); // Cập nhật lại mảng
            } else {
                setLstData([...lstData, { reportId: data.reportId, accomplishment: data.accomplishment }]);
            }
        }
    };
    
    return (
        <Table
            responsive
            className="tabel table-centered table-nowrap rounded mb-0"
        >
            <thead className="thead-light">
                <tr>
                    <th style={{ textAlign: "center" }}><div>Date</div></th>
                    <th style={{ textAlign: "center" }}><div>Location</div></th>
                    <th style={{ textAlign: "center" }}><div>Work item</div></th>
                    <th style={{ width: "200px", textAlign: "center" }}>Accomplishment</th>
                    <th style={{ textAlign: "center" }}><div>Unit</div></th>
                </tr>
            </thead>
            <tbody>
                {volumeData.map((item, index) => (
                    <TableRow
                        key={`page-actual-volume-${item.id}`}
                        {...item}
                        index={index}
                        handleUpdateData = {handleUpdateData}
                        volumeData = {pageReports}
                        SetVolumeData = {setPageReports}
                    />
                ))}
            </tbody>
        </Table>
    );
};
export default TableAccomplishment;
