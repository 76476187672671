// authActions.js
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const updateUserData = (userData) => ({
  type: UPDATE_USER_DATA,
  payload: userData
});
