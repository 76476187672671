import React, { useEffect, useState } from "react";
import { BreadcrumbHeader } from "../../component/common/BreadcrumbHeader";
import './style.scss'
import { SearchOutlined } from "@ant-design/icons";
import { Space, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import TableAccomplishment from "../../component/report/accomplishment/TableAccomplishment";
import PaginationCommon from "../../component/common/Pagination";
import Button from "../../component/common/Button";
import { PAGESIZE } from "../../constants";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { getSiteId } from "../../utils/auth";
import { useSelector } from "react-redux";
import { getListReportAccomplishment, updateListAccomplishment } from "../../services/Report";
import { ToastFailed, ToastSuccess } from "../../component/common/Toast";
import { ToastContainer } from "react-toastify";
import { useEditing } from "../../hook/EdittingContext";
const { RangePicker } = DatePicker;
dayjs.extend(utc);
const items = [
    {
        title: 'Report',
    },
    {
        title: 'Accomplishment',
    },
]

export default () => {
    const { isEditing, setIsEditing } = useEditing();
    let siteId = useSelector((state) => state.site).selectedSite?.siteId || JSON.parse(getSiteId())?.siteId;
    const [modelSearch, setModelSearch] = useState({
        StartDate: dayjs().subtract(7, 'day'),
        EndDate: dayjs(),
        SiteId: siteId,
        Search: null,
        CurrentPage: 1,
        DisplayItem: PAGESIZE,
    })

    const [rangeValue, setRangeValue] = useState([dayjs().subtract(7, 'day'), dayjs()]);
    const handleDateChange = (dates) => {
        setPageIndex(1)
        if (dates) {
            // Cập nhật modelSearch
            const [start, end] = dates;
            setRangeValue(dates);
            setModelSearch({
                ...modelSearch,
                StartDate: start,
                EndDate: end
            });
        }
        else {
            setRangeValue([]);
            setModelSearch({
                ...modelSearch,
                StartDate: null,
                EndDate: null,
            });
        }
    };

    const handleInputChange = (e) => {
        setPageIndex(1)
        setModelSearch({
            ...modelSearch,
            Search: e.target.value
        });
    }

    const [totalRecord, setTotalRecord] = useState(0);
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(PAGESIZE)
    const [pageReports, setPageReports] = useState([]);
    const getListData = async () => {
        var request = modelSearch;
        request.CurrentPage = pageIndex;
        request.DisplayItem = pageSize;
        request.SiteId = siteId;
        request.StartDate = modelSearch.StartDate ? dayjs(modelSearch.StartDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
        request.EndDate = modelSearch.EndDate ? dayjs(modelSearch.EndDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null;
        try {
            const response = await getListReportAccomplishment(request)
            if (response?.header?.resultCode === "1000") {
                setPageReports(response?.records?.listReports?.reports);
                setTotalRecord(response?.records?.listReports?.totalRecord);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const [lstData, setLstData] = useState([]);
    const [loading, setLoading] = useState(false)
    const onSave = async () => {
        if (lstData.length > 0) {
            setLoading(true);
            const data = lstData.map(item => ({
                ReportId: item.reportId,
                Accomplishment: item.accomplishment
            }));
            try {
                const payload = { listData: data };
                const response = await updateListAccomplishment(payload)
                if (response?.header?.resultCode === "1000") {
                    ToastSuccess("Update accomplishment successfully.");
                    getListData();
                }
                else {
                    ToastFailed("Accomplishment update failed!");
                }
            } catch (e) {
                ToastFailed("Accomplishment update failed!");
            } finally {
                setLoading(false)
                setIsEditing(false)
            }
        }
    }

    useEffect(() => {
        setModelSearch({ ...modelSearch, CurrentPage: pageIndex, DisplayItem: pageSize });
        getListData();
    }, [pageIndex, pageSize, modelSearch.StartDate, modelSearch.EndDate, modelSearch.Search, siteId]);

    return (
        <>
            <BreadcrumbHeader title={"Accomplishment"} items={items} />
            <ToastContainer />
            <div className="content">
                <div className="actual-volume-page">
                    <div className="form-table-actual-volume">
                        <div className="d-flex gap-2">
                            <Space direction="vertical" size={12}>
                                <RangePicker format="YYYY-MM-DD" value={rangeValue}
                                    onChange={handleDateChange} />
                            </Space>
                            <Search
                                placeholder="Search" maxLength={100} value={modelSearch.Search} onChange={handleInputChange}
                                style={{
                                    width: 400,
                                }}
                            />
                            <Button title={"Save"} handleClick={onSave} loading={loading} isDisable={lstData.length == 0} />
                        </div>
                        <div className="table-actual-volume">
                            <TableAccomplishment pageReports={pageReports} setLstData={setLstData} lstData={lstData} setPageReports = {setPageReports}/>
                        </div>
                        <div className="text-end">
                            <PaginationCommon totalRecord={totalRecord}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize} 
                                />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
