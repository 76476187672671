import { createAxiosClient } from "./createAxiosClient";
import { notification } from 'antd';

const REFRESH_TOKEN_URL = `${process.env.REACT_APP_BACKEND_URL}/user/auth/refresh-token`;
const BASE_URL = `${process.env.REACT_APP_BACKEND_URL}`;
let isLogoutNotified = false;

function getCurrentAccessToken() {
    return JSON.parse(localStorage.getItem("timesheet_userData"))?.token;
}

function getCurrentRefreshToken() {
    return JSON.parse(localStorage.getItem("timesheet_userData"))?.refresh;
}

function setRefreshedTokens(tokens) {
    let userData = JSON.parse(localStorage.getItem("timesheet_userData"));
    userData.access_token = tokens.access_token;
    userData.refresh = tokens.refresh;

    localStorage.setItem("timesheet_userData", JSON.stringify(userData));
}

function logout() {
    if (!isLogoutNotified) { // Kiểm tra nếu thông báo chưa được gửi
        isLogoutNotified = true;
        notification.warning({
            message: 'Session Expired',
            description: 'Your login session has ended. Please log in again.',
            duration: 3,
        });

        setTimeout(() => {
            localStorage.removeItem("timesheet_userData");
            localStorage.removeItem("timesheet_isLoggedIn");
            localStorage.removeItem("timesheet_userId");
            window.location.reload();
        }, 3000);
    }
}

const client = createAxiosClient({
    options: {
        baseURL: BASE_URL,
        timeout: 300000,
        headers: {
            'Content-Type': 'application/json',
        }
    },
    getCurrentAccessToken,
    getCurrentRefreshToken,
    refreshTokenUrl: REFRESH_TOKEN_URL,
    logout,
    setRefreshedTokens
});

export default client;
