import { Header } from "../Header";
import { Outlet, useLocation } from "react-router-dom";
import SidebarSystemManagement from "./SidebarSystemManagement";
import { Routes } from "../../../../Routes";

export const LayoutSystemManagement = () => {
    const location = useLocation();
    const path = location.pathname;

    return (
        <div style={{ flexDirection: "column", position: "relative", minHeight: "100vh", height: "auto" }} className="d-flex">
            <Header />
            <div className="d-flex">
                {path !== Routes.Management.path &&
                    <SidebarSystemManagement />
                }
                <div className="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
