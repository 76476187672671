import client from "../api/axiosClient";

const exportReportSite = async (request) => {
    return await client.post('/api/report/exportReportSite', request, {
        responseType: 'blob',
    });
};

const exportListReportSite = async (request) => {
    return await client.post('/api/report/exportListReportSite', request, {
        responseType: 'blob',
    });
};

export {
    exportReportSite,
    exportListReportSite
}