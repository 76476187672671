// ConfirmPopup.js
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const CancelPopup = forwardRef(({ onConfirm, onCancel, message }, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal: () => setIsModalVisible(true),
  }));

  const handleCancel = () => {
    setIsModalVisible(false);
    if (onCancel) onCancel();
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    if (onConfirm) onConfirm();
  };

  return (
    <Modal
      title={
        <span>
          <ExclamationCircleOutlined style={{ color: '#faad14', marginRight: 8 }} />
          Warning
        </span>
      }
      className='modal-confirm-leave-page'
      visible={isModalVisible}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText="Confirm"
      cancelText="Cancel"
    >
      <p>{message}</p>
    </Modal>
  );
});

export default CancelPopup;