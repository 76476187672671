import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/timesheet_logo_2.png"
import ImgProfile from "../../../assets/images/img_profile.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Modal, Popconfirm, Select, Space } from 'antd';
import { KeyOutlined, LeftCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { Routes } from "../../../Routes";
import "../style.scss"
import { apiGetListSitesEnable } from "../../../services/Site";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedSiteReducer } from "../../../store/actions/siteAction";
import { logoutSuccess } from "../../../store/actions/authAction";
import ModalChangePassword from "../../auth/ModalChangePassword";
import { getSiteId, setSiteLocal } from "../../../utils/auth";
import { Role } from "../../../utils/Role";
// import CancelPopup from "../../common/CancelPopup";
import { useEditing } from "../../../hook/EdittingContext";

export const HeaderUser = () => {
    const { isEditing, setIsEditing } = useEditing();
    const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false);

    const [optionsSiteEnable, setOptionsSiteEnable] = useState([])
    const userData = JSON.parse(localStorage.getItem('timesheet_userData'));

    let siteSelected = useSelector((state) => state.site).selectedSite?.siteId || (JSON.parse(getSiteId()))?.siteId;

    const [selectedSite, setSelectedSite] = useState(siteSelected);

    const userRole = useSelector((state) => state.auth.userData?.role) || JSON.parse(localStorage.getItem("timesheet_userData"))?.role;
    let isLoggedIn = useSelector((state) => state.auth).isLoggedIn || JSON.parse(localStorage.getItem("timesheet_isLoggedIn"));

    const navigate = useNavigate()
    const location = useLocation();
    const [isShowOptionsSite, setIsShowOptionsSite] = useState(false)

    const dispatch = useDispatch();


    useEffect(() => {
        const path = location.pathname;
        if (path === Routes.Management.path) setIsShowOptionsSite(false)
        else setIsShowOptionsSite(true)
    }, [location])

    function handleLogout() {
        setIsEditing(false)
        localStorage.removeItem("timesheet_userData");
        localStorage.removeItem("timesheet_isLoggedIn");
        dispatch(logoutSuccess())
        navigate(Routes.Login.path)
    }

    const handleGetListSites = async () => {
        if (userRole === Role.User) {
            const listSites = userData.siteIds || [];
            if (listSites.length > 0) {
                setOptionsSiteEnable([{
                    label: listSites[0].siteName,
                    value: listSites[0].siteId
                }])
            }
        } else {
            //role subadmin and admin
            try {
                const response = await apiGetListSitesEnable({ pageIndex: 1, pageSize: 10000 })
                if (response?.header?.resultCode === "1000") {
                    const listSites = response.records.listSite.sites;
                    const newOptions = listSites.map((item) => ({
                        label: item.siteName,
                        value: item.siteId
                    }))
                    setOptionsSiteEnable(newOptions)
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
    useEffect(() => {
        handleGetListSites()
    }, [])

    const siteDefault = JSON.parse(getSiteId());
    const handleChangeSite = (value, option) => {
        if (siteDefault.siteId !== value && location.pathname === Routes.NewReport.path) {
            Modal.confirm({
                title: "Warning",
                content: "Your changes have not been saved. Are you sure you want to leave this page?",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: () => {
                    setIsEditing(false);
                    setSelectedSite(value)
                    const newSite = {
                        siteId: option.value,
                        siteName: option.label
                    }
                    setSiteLocal(newSite)
                    dispatch(updateSelectedSiteReducer(newSite))
                    navigate(Routes.ListReports.path)
                },
            });
        } else if (isEditing) {
            Modal.confirm({
                title: "Warning",
                content: "Your changes have not been saved. Are you sure you want to leave this page?",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: () => {
                    setIsEditing(false);
                    setSelectedSite(value)
                    const newSite = {
                        siteId: option.value,
                        siteName: option.label
                    }
                    setSiteLocal(newSite)
                    dispatch(updateSelectedSiteReducer(newSite))
                },
            });
        } else {
            setSelectedSite(value)
            const newSite = {
                siteId: option.value,
                siteName: option.label
            }
            setSiteLocal(newSite)
            dispatch(updateSelectedSiteReducer(newSite))
        }
    }

    const showModalChangePassword = () => {
        setIsOpenModalChangePassword(true);
    }

    const handleReturnMenu = ()=>{
        if (isEditing) {
            Modal.confirm({
                title: "Warning",
                content: "Your changes have not been saved. Are you sure you want to leave this page?",
                okText: "Confirm",
                cancelText: "Cancel",
                onOk: () => {
                    setIsEditing(false);
                    navigate(Routes.Management.path)
                },
            })
        }else{
            navigate(Routes.Management.path)
        }
    }

    const items = [
        {
            label:<div>
                    <div className="d-flex gap-3" onClick={handleReturnMenu}><LeftCircleOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /> <span>Main menu</span></div>
                </div>,
            key: '0',
        },
        {
            label: <div className="d-flex gap-3" onClick={showModalChangePassword}><KeyOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /><span>Change Password</span></div>,
            key: '1',
        },
        {
            label: <Popconfirm
                title="Are you sure you want to log out?"
                okText="OK"
                cancelText="Cancel"
                onConfirm={handleLogout}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="d-flex gap-3"><LogoutOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /> Logout</div>
            </Popconfirm>,
            key: '3',
        },
    ];
    const itemsSystem = [
        {
            label: <div className="d-flex gap-3" onClick={showModalChangePassword}><KeyOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /><span>Change Password</span></div>,
            key: '1',
        },
        {
            label: <Popconfirm
                title="Are you sure you want to log out?"
                okText="OK"
                cancelText="Cancel"
                onConfirm={handleLogout}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="d-flex gap-3"><LogoutOutlined style={{ color: "rgba(24, 144, 255, 1)" }} /> Logout</div>
            </Popconfirm>,
            key: '3',
        },
    ];

    const [searchValue, setSearchValue] = useState("");
    const handleSearch = (input) => {
        if (input.length <= 100) {
            setSearchValue(input);
        }
    };

    return (
        <header className="d-flex justify-content-between align-items-center">
            <ModalChangePassword
                isModalOpen={isOpenModalChangePassword}
                setIsModalOpen={setIsOpenModalChangePassword} />
            <div className="d-flex justify-content-between align-items-center">
                <img src={Logo} alt="logo" height={40} />
            </div>
            <div className="d-flex gap-3">
                {isLoggedIn &&
                    <>
                        {isShowOptionsSite &&
                            <div>
                                <Select
                                    style={{ width: "360px" }}
                                    showSearch
                                    value={selectedSite}
                                    searchValue={searchValue}
                                    onChange={handleChangeSite}
                                    options={optionsSiteEnable}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    onSearch={handleSearch}
                                />
                            </div>
                        }

                        <Dropdown
                            menu={{ items: userRole === 0 || userRole === 1 ? items : itemsSystem }}
                            trigger={['click']}
                            className="cursor-pointer"
                        >
                            <Space>
                                <div className="d-flex gap-2">
                                    <img src={ImgProfile} width={24} height={24} alt="image" />
                                    <div>{userData?.fullName}</div>
                                </div>
                            </Space>
                        </Dropdown>
                    </>
                }
            </div>
        </header>
    );
};
